<template>
  <div class="footer">
    <div class="footer-box">
      <div class="footer_column">
        <a href="https://kustomizer.kobesportswear.com">
          <el-image class="img" src="https://kobesportswear.com/wp-content/themes/kobesportswear/img/refresh/kobe_sportswear_logo.png" fit="contain" />
        </a>

      </div>
      <div class="footer_column">
        <ul class="nav">
          <li><a href="https://kobesportswear.com/company/" target="_blank">ABOUT US</a></li>
          <li><a href="https://kobesportswear.com/contact-us/" target="_blank">CONTACT US</a></li>
          <li><a href="https://kobesportswear.com/careers/" target="_blank">CAREERS</a></li>
        </ul>
      </div>
      <div class="footer_column">
        <ul class="nav">
          <li>FIND US</li>
        </ul>
        <div class="content">
          KOBE SPORTSWEAR<br />
          <a href="https://www.google.com/maps/dir//Kobe+Sportswear,+791+Tapscott+Rd,+Scarborough,+ON+M1X+1A2,+Canada/@43.8181694,-79.2411362,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x89d4d72b7ff9dfad:0xc109fe5cf7d6631e!2m2!1d-79.2391705!2d43.8178559!3e0" target="_blank">
            791 TAPSCOTT RD<br />
            SCARBOROUGH, ON M1X 1A2<br /><br />
          </a>

          <a href="tel:416-754-7024" title="Phone">416-754-7024</a><br />
          <a href="tel:1-888-898-5623" title="Toll Free">1-888-898-5623 </a><br />
          <a href="mailto:info@kobesportswear.com" title="Email">info@kobesportswear.com</a><br /><br />
        </div>
        <div class="content">Follow us on Social</div>
        <div class="social">
          <div class="social">
            <a href="https://www.instagram.com/KobeSportswear" target="_blank" title="Instagram"><div class="icon ig"></div></a>
            <a href="https://www.facebook.com/KobeSportswear" target="_blank" title="Facebook"><div class="icon fb"></div></a>
            <a href="https://twitter.com/KobeSportswear" target="_blank" title="Twitter"><div class="icon tw"></div></a>
          </div>
        </div>
      </div>
      <div class="footer_column">
        <ul class="nav">
          <li>SUBSCRIBE TO OUR NEWSLETTER</li>
          <input class="input" type="text" />
          <button class="mc-embedded-subscribe-refresh"></button>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  min-width: 1600px;
  //   height: 455px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-box {
    max-width: 80%;
    min-width: 1300px;
    height: 100%;
    padding: 60px 0px;
    display: flex;
    align-items: flex-start;
    .footer_column {
      width: 25%;
      .input {
        height: 40px;
        font-size: 20px;
        width: 100%;
        border-radius: 25px;
        padding-left: 15px;
      }
      .input:focus {
        border: 1px solid springgreen;
      }
      .mc-embedded-subscribe-refresh {
        height: 35px;
        width: 35px;
        background-color: #000;
        background: url(https://kobesportswear.com/wp-content/themes/kobesportswear/img/refresh/white-arrow.png);
        background-size: contain;
        border: none;
        border-radius: 50%;
        position: relative;
        left: 160px;
        top: -40px;

        cursor: pointer;
      }
      .social {
        display: flex;
      }
      .content {
        text-align: left;
        color: white;
        font-size: 16px;
        line-height: 24px;
      }
      .icon {
        width: 31.5px;
        height: 31.5px;
        margin: 10px 15px 0 0;
        background-size: cover;
        cursor: pointer;
      }
      .ig {
        background-image: url("@/assets/ig_w.png")
      }
      .ig:hover {
        background-image: url("@/assets/ig_r.png")
      }
      .fb {
        background-image: url("@/assets/fb_w.png")
      }
      .fb:hover {
        background-image: url("@/assets/fb_r.png")
      }
      .tw {
        background-image: url("@/assets/tw_w.png")
      }
      .tw:hover {
        background-image: url("@/assets/tw_r.png")
      }
      a {
        text-decoration: none;
        color: white;
      }
      a:hover {
        color: red;
      }
      .img {
        height: 40px;
      }
      .nav {
        width: 100%;
        margin: 0px;
        padding: 0px;
        // margin-left: 0px;
        li {
          list-style-type: none;
          margin-bottom: 20px;
          font-size: 18px;
          color: white;
          text-align: left;
          //   font-family: "Helvetica";
        }
      }
    }
  }
}
</style>