
import { defineComponent } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    const goHome = (i: any) => {
      window.location.reload()
      // window.location.replace("http://localhost:8080/")
      window.location.replace("https://kustomizer.kobesportswear.com")
    }
    return {
      goHome,
      Search,
    }
  },
})
