
import { defineComponent, onMounted, reactive, toRefs, ref, toRaw } from 'vue'
import {
  apiGetUserInfoz,
  apiGetUserInfof,
  gettotalarealistid,
  gettotalarealist,
  getTextFont,
  svgupload,
  uploadhtml,
  insertOrder,
  getColorList,
  getOrderNumber,
} from '@/api/index'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import {getGoodsInfo} from "@/api/page";

export default defineComponent({
  setup() {
    const router = useRouter()
    const ruleFormRef = ref<FormInstance>()
    const rules = reactive<FormRules>({
      name: [
        {
          required: true,
          message: 'Please input Activity name',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 25,
          message: 'Length should be 3 to 25',
          trigger: 'blur',
        },
      ],
      phone: [
        {
          required: true,
          message: 'Please input Activity phone',
          trigger: 'blur',
        },
        {
          max: 15,
          message: 'Please input phone',
          trigger: 'blur',
        },
      ],
      email: [
        {
          required: true,
          message: 'Please input email address',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: 'Please input correct email address',
          trigger: ['blur', 'change'],
        },
      ],
      reemail: [
        {
          required: true,
          message: 'Please input reemail address',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: 'Please input correct reemail address',
          trigger: ['blur', 'change'],
        },
      ],
      address: [
        {
          required: true,
          message: 'Please select Activity address',
          trigger: 'change',
        },
      ],
    })
    const state = reactive({
      show: 0 as any,
      ysshow: 0 as any,
      showBack: false as any,
      value2: true as any,
      value1: true as any,
      id: '' as any,
      svg: '' as any,
      svg2: '' as any,
      // 总区域列表
      totalarealist: '' as any,
      ztareaId: '' as any,

      // 待选颜色
      colorList: '' as any,
      colorSelectedIndex: -1,

      // 文字样式列表
      textFontList: '' as any,


      loading: false as any,
      // 对话框显示隐藏
      dialogVisible: false as any,
      // exit对话框显示隐藏
      exitDialogVisible: false as any,
      // 颜色名称
      colorname: '' as any,
      //表单信息
      // 初始色块区域
      Initialblockarea: '' as any,
      // 选中的衣角
      coatvalue: 'RightAngle',
      ruleForm: {
        name: '',
        phone: '',
        email: '',
        reemail: '',
        address: '',
      },
      // 商品名
      goodsName: '' as any,

      // 订单信息
      orderinfo: {
        goodsDetailsId: '',
        clientName: '',
        clientPhone: '',
        clientEmail: '',
        clientAddress: '',
        orderNumber: '',
        orderFile: '',
        atSizeList: []
      } as any,
      orderPdfUrl: [] as any,
      atSizeList: {
        youth: [
            0, 0, 0, 0, 0, 0
        ],
        adult: [
          0, 0, 0, 0, 0, 0, 0, 0, 0,
        ]
      },

      //mockup_details.img_url_1
      textMockupDetails: {
        font1: '',
        location1: '',
        size1: '',
        color1: '',

        font2: '',
        location2: '',
        size2: '',
        color2: '',

        font3: '',
        location3: '',
        size3: '',
        color3: '',

        font4: '',
        location4: '',
        size4: '',
        color4: '',
      },

      imgMockupDetails: {
        imgUrl1: '',
        application1: '',
        location1: '',
        size1: '',

        imgUrl2: '',
        application2: '',
        location2: '',
        size2: '',

        imgUrl3: '',
        application3: '',
        location3: '',
        size3: '',

        imgUrl4: '',
        application4: '',
        location4: '',
        size4: '',
      },

      colorMockup: '' as any,

      // 获取详情表格的容器
      mockupDetails: '' as any,
      // 获取衣领的表格
      collarMockupDetails: '' as any,
      collarMockupDetailsNeckType: '' as any,
      collarMockupDetailsNeckTypeColor: '' as any,
      collarMockupDetailsUnderlayColor: '' as any,
      collarMockupDetailsLaceColor: '' as any,
      // 获取衣服底部特征
      bottomFeaturesMockupDetails: '' as any,
      bottomFeaturesMockupDetailsBottom: '' as any,
      bottomFeaturesMockupDetailsSplit: '' as any,
      bottomFeaturesMockupDetailsJerseyTieDown: '' as any,


      // 获取文字记录的表格
      textMockupDetails1: '' as any,
      textMockupDetails2: '' as any,
      textMockupDetails3: '' as any,
      textMockupDetails4: '' as any,
      // 文字记录的title
      textMockupDetailsTitle1: '' as any,
      textMockupDetailsTitle2: '' as any,
      textMockupDetailsTitle3: '' as any,
      textMockupDetailsTitle4: '' as any,
      // 文字记录的value
      textMockupDetailsValue1: '' as any,
      textMockupDetailsValue2: '' as any,
      textMockupDetailsValue3: '' as any,
      textMockupDetailsValue4: '' as any,
      // 文字记录的font
      textMockupDetailsFont1: '' as any,
      textMockupDetailsFont2: '' as any,
      textMockupDetailsFont3: '' as any,
      textMockupDetailsFont4: '' as any,
      // 文字记录的application
      textMockupDetailsApplication1: '' as any,
      textMockupDetailsApplication2: '' as any,
      textMockupDetailsApplication3: '' as any,
      textMockupDetailsApplication4: '' as any,
      // 文字记录的location
      textMockupDetailsLocation1: '' as any,
      textMockupDetailsLocation2: '' as any,
      textMockupDetailsLocation3: '' as any,
      textMockupDetailsLocation4: '' as any,
      // 文字记录的size
      textMockupDetailsSize1: '' as any,
      textMockupDetailsSize2: '' as any,
      textMockupDetailsSize3: '' as any,
      textMockupDetailsSize4: '' as any,
      // 文字记录的color
      textMockupDetailsColor1: '' as any,
      textMockupDetailsColor2: '' as any,
      textMockupDetailsColor3: '' as any,
      textMockupDetailsColor4: '' as any,

      // 获取没有size文字记录的表格
      textMockupDetailsNoSize1: '' as any,
      textMockupDetailsNoSize2: '' as any,
      textMockupDetailsNoSize3: '' as any,
      textMockupDetailsNoSize4: '' as any,
      // 文字记录的title
      textMockupDetailsTitleNoSize1: '' as any,
      textMockupDetailsTitleNoSize2: '' as any,
      textMockupDetailsTitleNoSize3: '' as any,
      textMockupDetailsTitleNoSize4: '' as any,
      // 文字记录的value
      textMockupDetailsValueNoSize1: '' as any,
      textMockupDetailsValueNoSize2: '' as any,
      textMockupDetailsValueNoSize3: '' as any,
      textMockupDetailsValueNoSize4: '' as any,
      // 文字记录的font
      textMockupDetailsFontNoSize1: '' as any,
      textMockupDetailsFontNoSize2: '' as any,
      textMockupDetailsFontNoSize3: '' as any,
      textMockupDetailsFontNoSize4: '' as any,
      // 文字记录的application
      textMockupDetailsApplicationNoSize1: '' as any,
      textMockupDetailsApplicationNoSize2: '' as any,
      textMockupDetailsApplicationNoSize3: '' as any,
      textMockupDetailsApplicationNoSize4: '' as any,
      // 文字记录的location
      textMockupDetailsLocationNoSize1: '' as any,
      textMockupDetailsLocationNoSize2: '' as any,
      textMockupDetailsLocationNoSize3: '' as any,
      textMockupDetailsLocationNoSize4: '' as any,
      // 文字记录的color
      textMockupDetailsColorNoSize1: '' as any,
      textMockupDetailsColorNoSize2: '' as any,
      textMockupDetailsColorNoSize3: '' as any,
      textMockupDetailsColorNoSize4: '' as any,

      // 获取图片记录的表格
      imgMockupDetails1: '' as any,
      imgMockupDetails2: '' as any,
      imgMockupDetails3: '' as any,
      imgMockupDetails4: '' as any,
      // 图片记录的title
      imgMockupDetailsTitle1: '' as any,
      imgMockupDetailsTitle2: '' as any,
      imgMockupDetailsTitle3: '' as any,
      imgMockupDetailsTitle4: '' as any,
      // 图片记录的URL
      imgMockupDetailsUrl1: '' as any,
      imgMockupDetailsUrl2: '' as any,
      imgMockupDetailsUrl3: '' as any,
      imgMockupDetailsUrl4: '' as any,
      // 图片记录的application
      imgMockupDetailsApplication1: '' as any,
      imgMockupDetailsApplication2: '' as any,
      imgMockupDetailsApplication3: '' as any,
      imgMockupDetailsApplication4: '' as any,
      // 图片记录的location
      imgMockupDetailsLocation1: '' as any,
      imgMockupDetailsLocation2: '' as any,
      imgMockupDetailsLocation3: '' as any,
      imgMockupDetailsLocation4: '' as any,
      // 图片记录的size
      imgMockupDetailsSize1: '' as any,
      imgMockupDetailsSize2: '' as any,
      imgMockupDetailsSize3: '' as any,
      imgMockupDetailsSize4: '' as any,

      // 获取图片记录的表格
      imgMockupDetailsNoSize1: '' as any,
      imgMockupDetailsNoSize2: '' as any,
      imgMockupDetailsNoSize3: '' as any,
      imgMockupDetailsNoSize4: '' as any,
      // 图片记录的title
      imgMockupDetailsTitleNoSize1: '' as any,
      imgMockupDetailsTitleNoSize2: '' as any,
      imgMockupDetailsTitleNoSize3: '' as any,
      imgMockupDetailsTitleNoSize4: '' as any,
      // 图片记录的URL
      imgMockupDetailsUrlNoSize1: '' as any,
      imgMockupDetailsUrlNoSize2: '' as any,
      imgMockupDetailsUrlNoSize3: '' as any,
      imgMockupDetailsUrlNoSize4: '' as any,
      // 图片记录的application
      imgMockupDetailsApplicationNoSize1: '' as any,
      imgMockupDetailsApplicationNoSize2: '' as any,
      imgMockupDetailsApplicationNoSize3: '' as any,
      imgMockupDetailsApplicationNoSize4: '' as any,
      // 图片记录的location
      imgMockupDetailsLocationNoSize1: '' as any,
      imgMockupDetailsLocationNoSize2: '' as any,
      imgMockupDetailsLocationNoSize3: '' as any,
      imgMockupDetailsLocationNoSize4: '' as any,

      pngDataUrl: '',
      colorMockupList: '' as any,
      fabric: '',
      createOrderTimes: '',
      // 衣领区域
      // 选中的衣领id
      collarSelecteArea: '' as any,
      // 选中颜色的下标
      neckColorSelectedIndex: 0,
      // 类型
      havaCollar: 'true' as any,


      teamLogoFileName: 'No File selected',
      sponsorLogoFileName: 'No File selected',

      // 操作栏操作列表
      operationDataList: [] as any,
      operationOldData: '' as any,

      pageTowPageNumber: 0 as any,
      totalPageNumber: 0 as any,
      // 当前页数
      currentPageNumber: 1 as any,
      // 当前表格总条数
      currentTotalTableNumber: 0 as any,
      // 当前页的表格条数
      currentTableNumber: 0 as any,

      // Body 数据
      bodyColorList: [] as any,
      // 选中下标
      bodyColorSelectedIndex: -1,
      // 衣服主颜色
      bodyMainColor: "#FFFFFF",
      bodyMainColorIndex: 0 as any,
      // 衣服字颜色相反的颜色
      bodyMainContraryColor: "#FFFFFF",
      bodyMainContraryColorName: "WHITE",

      // Collar 数据
      collarTypeList: [] as any,
      // 衣领类型选中下标
      collarTypeSelectedInitialIndex: '' as any,
      collarTypeSelectedOldIndex: '' as any,
      collarTypeSelectedIndex: '' as any,
      // 衣领数据
      collarAreaData: '' as any,
      collarClassNameData: [] as any,
      // 衣服颜色数量数据
      collarColorNumberList: [] as any,
      // 衣领颜色
      collarColorList: [] as any,
      collarColorNameList: [] as any,
      collarColorNumber: '' as any,
      collarColorSelectedIndex: -1,
      // 衬底颜色
      collarUnderlayClassName: '',
      collarUnderlayColor: '',
      collarUnderlayColorName: '',
      collarUnderlayColorSelectedIndex: -1,
      // 蕾丝颜色
      collarLaceClassName: '',
      collarLaceColor: '',
      collarLaceColorName: '',
      collarLaceColorSelectedIndex: -1,

      // 衣角底部 数据
      cornerBottomList: [] as any,
      cornerBottomSelectedOldIndex: 0,
      cornerBottomSelectedIndex: 0,
      cornerBottomLable: '',
      cornerBottomSplitList: [
        {
          label: 'No',
          value: 'No'
        },
        {
          label: 'Yes',
          value: 'Yes'
        }
      ],
      cornerBottomSplitValue: '',

      // Team Logo 数据
      teamLogoApplication: 'Screen Print' as any,
      teamLogoApplicationList: [
        {
          label: 'Screen Print',
          value: 'Screen Print'
        },
        {
          label: 'Sublimated Twill',
          value: 'Sublimated Twill'
        },
        {
          label: 'Embroidered',
          value: 'Embroidered'
        },
        {
          label: 'Heat Transfer',
          value: 'Heat Transfer'
        },
      ],
      // 初始数据
      teamLogoDataList: [] as any,
      teamLogoLocationList: [] as any,
      teamLogoLocationSelectedOldIndex: '' as any,
      teamLogoLocationSelectedIndex: '' as any,
      teamLogoSizeList: [] as any,
      teamLogoSizeSelectedIndex: '' as any,
      teamLogoImgUrl: '' as any,
      teamLogoButtonValue: 'Upload Logo',

      // Number 数据
      numberApplication: 'Screen Print' as any,
      numberApplicationList: [
        {
          label: 'Screen Print',
          value: 'Screen Print'
        },
        {
          label: 'Sublimated Twill',
          value: 'Sublimated Twill'
        },
        {
          label: 'Embroidered',
          value: 'Embroidered'
        },
        {
          label: 'Heat Transfer',
          value: 'Heat Transfer'
        },
      ],
      // 初始数据
      numberDataList: [] as any,
      numberLocationList: [] as any,
      numberLocationSelectedOldIndex: '' as any,
      numberLocationSelectedIndex: '' as any,
      numberSizeList: [] as any,
      numberSizeSelectedIndex: '' as any,
      // number选中的文字
      numberFamily: '' as any,
      // number选中的几种颜色
      numberColorNumber: '' as any,
      // 选择颜色的下标
      numberColorSelectedIndex: -1,
      // 选择的颜色
      numberColor: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      numberColorName: [
        'WHITE',
        'WHITE',
        'WHITE',
      ],
      numberValue: '28' as any,

      // Player Name 数据
      playerNameApplication: 'Sub Name Bar' as any,
      playerNameApplicationList: [
        {
          label: 'Sub Name Bar',
          value: 'Sub Name Bar'
        },
        {
          label: 'Direct Twill Letters',
          value: 'Direct Twill Letters'
        },
        {
          label: 'Direct Screen Print',
          value: 'Direct Screen Print'
        },
        {
          label: 'Sub Twill Name Bar',
          value: 'Sub Twill Name Bar'
        },
      ],
      // 初始数据
      playerNameDataList: [] as any,
      playerNameLocationList: [] as any,
      playerNameLocationSelectedIndex: 0 as any,
      playerNameSizeList: [] as any,
      playerNameSizeSelectedIndex: 0 as any,
      playerNameFamily: '' as any,
      playerNameColorNumber: '' as any,
      // 选择颜色的下标
      playerNameColorSelectedIndex: -1,
      // 选择的颜色
      playerNameColor: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      playerNameColorName: [
        'WHITE',
        'WHITE',
        'WHITE',
      ],
      playerNameValue: 'PLAYER' as any,

      // Team Name 数据
      teamNameApplication: 'Sub Name Bar' as any,
      teamNameApplicationList: [
        {
          label: 'Sub Name Bar',
          value: 'Sub Name Bar'
        },
        {
          label: 'Direct Twill Letters',
          value: 'Direct Twill Letters'
        },
        {
          label: 'Direct Screen Print',
          value: 'Direct Screen Print'
        },
        {
          label: 'Sub Twill Name Bar',
          value: 'Sub Twill Name Bar'
        },
      ],
      // teamname选中的区域
      teamNameSelecteAreaOld: '' as any,
      teamNameSelecteArea: '' as any,
      //teamname选中的文字
      teamNameFamily: '' as any,
      // teamname选中的几种颜色
      teamNameColorNumber: '' as any,
      // 选择颜色的下标
      teamNameColorSelectedIndex: -1,
      // 选择的颜色
      teamNameNameColor: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      teamNameNameColorName: [
        'WHITE',
        'WHITE',
        'WHITE',
      ],
      teamNameAreaList: '' as any,
      teamNameValue: 'MY TEAM' as any,

      // Sponsor Name数据
      sponsorNameApplication: 'Screen Print' as any,
      sponsorNameApplicationList: [
        {
          label: 'Screen Print',
          value: 'Screen Print'
        },
        {
          label: 'Sublimated Twill',
          value: 'Sublimated Twill'
        },
        {
          label: 'Embroidered',
          value: 'Embroidered'
        },
        {
          label: 'Heat Transfer',
          value: 'Heat Transfer'
        },
      ],
      // 初始数据
      sponsorNameDataList: [] as any,
      sponsorNameLocationList: [] as any,
      sponsorNameLocationSelectedOldIndex: 0 as any,
      sponsorNameLocationSelectedIndex: '' as any,
      sponsorNameSizeList: [] as any,
      sponsorNameSizeSelectedIndex: 0 as any,
      sponsorNameFamily: '' as any,
      sponsorNameColorNumber: '' as any,
      // 选择颜色的下标
      sponsorNameColorSelectedIndex: -1,
      // 选择的颜色
      sponsorNameColor: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      sponsorNameColorName: [
        'WHITE',
        'WHITE',
        'WHITE',
      ],
      sponsorNameValue: 'SPONSOR' as any,

      // Sponsor Logo 数据
      sponsorLogoApplication: 'Screen Print' as any,
      sponsorLogoApplicationList: [
        {
          label: 'Screen Print',
          value: 'Screen Print'
        },
        {
          label: 'Sublimated Twill',
          value: 'Sublimated Twill'
        },
        {
          label: 'Embroidered',
          value: 'Embroidered'
        },
        {
          label: 'Heat Transfer',
          value: 'Heat Transfer'
        },
      ],
      // 初始数据
      sponsorLogoDataList: [] as any,
      sponsorLogoLocationList: [] as any,
      sponsorLogoLocationSelectedOldIndex: '' as any,
      sponsorLogoLocationSelectedIndex: '' as any,
      sponsorLogoSizeList: [] as any,
      sponsorLogoSizeSelectedIndex: '' as any,
      sponsorLogoImgUrl: '' as any,
      sponsorLogoButtonValue: 'Upload Logo',

      // Flags & Stop Sign 数据
      canadaFlagShow: false as any,
      canadaFlagLocationList: [] as any,
      canadaFlagLocationSelectedOldIndex: '' as any,
      canadaFlagLocationSelectedIndex: '' as any,
      canadaFlagApplication: 'Screen Print' as any,
      canadaFlagImgUrl: 'https://kustomizer.kobesportswear.com/profile/upload/2023/06/15/db73033d-8551-4229-acd7-c352f6f3c0d3.png',

      usaFlagShow: false as any,
      usaFlagLocationList: [] as any,
      usaFlagLocationSelectedOldIndex: '' as any,
      usaFlagLocationSelectedIndex: '' as any,
      usaFlagApplication: 'Screen Print' as any,
      usaFlagImgUrl: 'https://kustomizer.kobesportswear.com/profile/upload/2023/06/15/c6a14348-a6d0-4c0e-9387-f5c3d9eaff80.png',

      stopSignShow: false as any,
      stopSignSwitchDisabled: false as any,
      stopSignLocationList: [] as any,
      stopSignLocationSelectedIndex: 0 as any,
      stopSignApplication: 'Screen Print' as any,
      stopSignImgUrl: 'https://kustomizer.kobesportswear.com/profile/upload/2023/06/15/4d75b7b9-9b1e-48c5-904a-93e6043abf60.png',

      // C A 数据
      caApplication: 'Screen Print' as any,
      caApplicationList: [
        {
          label: 'Screen Print',
          value: 'Screen Print'
        },
        {
          label: 'Sublimated Twill',
          value: 'Sublimated Twill'
        },
        {
          label: 'Embroidered',
          value: 'Embroidered'
        },
        {
          label: 'Heat Transfer',
          value: 'Heat Transfer'
        },
      ],
      caValue: '' as any,
      caValueList: [
        {
          label: 'A',
          value: 'A'
        },
        {
          label: 'C',
          value: 'C'
        }
      ],
      // 初始数据
      acDataList: [] as any,
      caLocationList: [] as any,
      acLocationSelectedOldIndex: '' as any,
      caLocationSelectedOldIndex: '' as any,
      caLocationSelectedIndex: '' as any,
      caFamily: '' as any,
      caColorNumber: '' as any,
      caColorNumberList:[
        {
          label: 'one color',
          value: 1,
        },
        {
          label: 'two colors',
          value: 2,
        }
      ],
      // 选择颜色的下标
      caColorSelectedIndex: -1,
      // 选择的颜色
      caColor: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      caColorName: [
        'WHITE',
        'WHITE',
        'WHITE',
      ],


      flagApplicationList: [
        {
          label: 'Screen Print',
          value: 'Screen Print'
        },
        {
          label: 'Sublimated Twill',
          value: 'Sublimated Twill'
        },
        {
          label: 'Embroidered',
          value: 'Embroidered'
        },
        {
          label: 'Heat Transfer',
          value: 'Heat Transfer'
        },
      ],

      textColorNumberList:[
        {
          label: 'one color',
          value: 1,
        },
        {
          label: 'two colors',
          value: 2,
        },
        {
          label: 'three colors',
          value: 3,
        }
      ],


      // sponsorname
      sponsornameapplication: 'Screen Print' as any,
      sponsornamesekearea: '' as any,
      sponsornamefamily: '' as any,
      sponsornamecolor: '1' as any,
      sponsornamenamecolor: '' as any,
      sponsornamenamecolor2: '' as any,
      sponsornamenamecolor3: '' as any,
      sponsornamearealist: '' as any,
      sponsornamevalue: '' as any,

      // ac
      acapplication: 'Screen Print' as any,
      acvalue: '' as any,
      acsekearea: '' as any,
      acfamily: '' as any,
      accolor: '1' as any,
      acarealist: '' as any,
      acnamecolor: '' as any,
      acnamecolor2: '' as any,
      acnamecolor3: '' as any,

      // 加拿大国旗显示隐藏
      canadasekearea: '' as any,
      canadaclasslist: '' as any,
      canadaapplication: 'Screen Print' as any,
      //美国国旗显示隐藏
      usasekearea: '' as any,
      usaclasslist: '' as any,
      usaapplication: 'Screen Print' as any,
      usashow: false as any,
      //stop显示隐藏
      stopsekearea: '' as any,
      stopclasslist: '' as any,
      stopapplication: 'Screen Print' as any,
      stopshow: false as any,
      jerseyTieDownShow: false as any,

      selectcollname: '' as any,

      coatlabel: '' as any,
    })
    const route = useRoute()

    // 旗帜图标的显示和隐藏
    const switchFlagShow = (type) => {
      switch (type) {
        case 'canada_flag':
          clearSvgImg(type)
          updateSvgImg(type)
          break
        case 'usa_flag':
          clearSvgImg(type)
          updateSvgImg(type)
          break
        case 'stop_sign':
          clearSvgImg(type)
          updateSvgImg(type)
          break
      }
    }

    // 选择位置
    const selecteLocation = (type) => {
      switch (type) {
        case 'collar': {
          state.collarColorNumberList = []
          state.collarUnderlayClassName = ''
          state.collarUnderlayColor = ''
          state.collarUnderlayColorName = ''
          state.collarLaceClassName = ''
          state.collarLaceColor = ''
          state.collarLaceColorName = ''
          if (state.collarTypeSelectedIndex === '') {
            return
          }
          let key: any = state.collarTypeList[state.collarTypeSelectedIndex].className
          state.collarClassNameData = state.collarAreaData[key]
          for (let item of state.collarClassNameData) {
            switch (item.type) {
              case 0:
                // 衣领
                var info = {
                  label: item.modeName,
                  value: item.modeId,
                }
                state.collarColorNumberList.push(info)
                break
              case 1:
                //  衬底
                state.collarUnderlayClassName = item.svgClassNameList[0][0]
                state.collarUnderlayColor = getSvgColorHex(state.collarUnderlayClassName, 'fill')
                state.collarUnderlayColorName = getColorNameByColor(state.collarUnderlayColor)
                break
              case 2:
                // 蕾丝
                state.collarLaceClassName = item.svgClassNameList[0][0]
                state.collarLaceColor = getSvgColorHex(state.collarLaceClassName, 'stroke')
                state.collarLaceColorName = getColorNameByColor(state.collarLaceColor)
                break
            }
          }
          // 获取衣领颜色数量
          getCollarColorNumber()
          // 修改颜色数量
          selecteColorNumber(type)
          // 重置操作栏颜色选中
          initSwitchColor('')
          // 关闭颜色选项页面
          state.ysshow = 0
          // 更新SVG衣领类型
          let oldClassName: any = 'collar-def'
          if (state.collarTypeSelectedOldIndex !== '') {
            oldClassName = state.collarTypeList[state.collarTypeSelectedOldIndex].className
          }
          let className: any = state.collarTypeList[state.collarTypeSelectedIndex].className
          updateSvgDisplay(oldClassName, className)
          // 保存衣领下标旧值
          state.collarTypeSelectedOldIndex = state.collarTypeSelectedIndex

          break}
        case 'corner_bottom': {
          state.cornerBottomLable = state.cornerBottomList[state.cornerBottomSelectedIndex].label
          state.cornerBottomSplitValue = state.cornerBottomLable == 'Scoop' ? state.cornerBottomSplitList[0].label : ''
          // 更新SVG衣角底边
          let oldClassName: any = state.cornerBottomList[state.cornerBottomSelectedOldIndex].value
          let className: any = state.cornerBottomList[state.cornerBottomSelectedIndex].value
          updateSvgDisplay(oldClassName, className)
          state.cornerBottomSelectedOldIndex = state.cornerBottomSelectedIndex
          break}
        case 'team_logo':
          // 更换尺寸数据
          state.teamLogoSizeList = []
          state.teamLogoSizeSelectedIndex = ''
          for (let item of state.teamLogoDataList[state.teamLogoLocationSelectedIndex].sizeList) {
            let sizeInfo = {
              label: item.dictLabel,
              value: item.dictValue,
            }
            state.teamLogoSizeList.push(sizeInfo)
          }

          if (state.teamLogoImgUrl != '') {
            // 清除SVG图片
            clearSvgImg(type)
            // 新增SVG图片
            updateSvgImg(type)
          }
          // 获取SVG的实际尺寸
          getSvgSize(type)

          state.teamLogoLocationSelectedOldIndex = state.teamLogoLocationSelectedIndex
          break
        case 'number':
          // 更换尺寸数据
          state.numberSizeList = []
          state.numberSizeSelectedIndex = ''
          for (let item of state.numberDataList[state.numberLocationSelectedIndex].sizeList) {
            let sizeInfo = {
              label: item.dictLabel,
              value: item.dictValue,
            }
            state.numberSizeList.push(sizeInfo)
          }
          // 获取SVG的实际尺寸
          getSvgSize(type)
          clearSvgText(type)
          updateSvgColor(type)
          updateSvgTextFont(type)
          state.numberLocationSelectedOldIndex = state.numberLocationSelectedIndex
          break
        case 'team_name':
          // 如果第一次切换位置，自动设置一种颜色
          if (state.teamNameColorNumber === '') {
            state.teamNameColorNumber = 1
            selecteColorNumber(type)
          }
          // 根据字符长度设置字体大小
          if (state.teamNameSelecteArea !== '') {
              switch (state.teamNameSelecteArea[0]) {
                case 'team_name_f_center_chest':
                case 'team_name_b_upper_back':
                case 'team_name_b_lower_back':
                  updateSvgSize(type)
                  break
              }
          }
          clearSvgText(type)
          updateSvgColor(type)
          updateSvgTextFont(type)
          state.teamNameSelecteAreaOld = state.teamNameSelecteArea
          break
        case 'sponsor_name':
          // 更换尺寸数据
          state.sponsorNameSizeList = []
          for (let item of state.sponsorNameDataList[state.sponsorNameLocationSelectedIndex].sizeList) {
            let sizeInfo = {
              label: item.dictLabel,
              value: item.dictValue,
            }
            state.sponsorNameSizeList.push(sizeInfo)
          }
          // 如果第一次切换位置，自动设置一种颜色
          if (state.sponsorNameColorNumber === '') {
            state.sponsorNameColorNumber = 1
            selecteColorNumber(type)
          }
          // 根据字符长度设置字体大小
          if (state.sponsorNameLocationSelectedIndex !== '') {
            switch (state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className) {
              case 'sponsor_name_b_upper_back':
              case 'team_name_b_lower_back':
                updateSvgSize(type)
                break
            }
          }
          // 获取SVG的实际尺寸
          clearSvgText(type)
          updateSvgColor(type)
          updateSvgTextFont(type)
          state.sponsorNameLocationSelectedOldIndex = state.sponsorNameLocationSelectedIndex
          break
        case 'sponsor_logo':
          // 更换尺寸数据
          state.sponsorLogoSizeList = []
          state.sponsorLogoSizeSelectedIndex = ''
          for (let item of state.sponsorLogoDataList[state.sponsorLogoLocationSelectedIndex].sizeList) {
            let sizeInfo = {
              label: item.dictLabel,
              value: item.dictValue,
            }
            state.sponsorLogoSizeList.push(sizeInfo)
          }
          if (state.sponsorLogoImgUrl != '') {
            // 清除SVG图片
            clearSvgImg(type)
            // 新增SVG图片
            updateSvgImg(type)
          }
          // 获取SVG的实际尺寸
          getSvgSize(type)
          state.sponsorLogoLocationSelectedOldIndex = state.sponsorLogoLocationSelectedIndex
          break
        case 'canada_flag':
          if (state.canadaFlagLocationSelectedOldIndex in [0,1,2,3,4]) {
            banLocation(state.canadaFlagLocationList[state.canadaFlagLocationSelectedOldIndex].areaName, false)
          }
          banLocation(state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].areaName, true)

          // 清除SVG图片
          clearSvgImg(type)
          // 新增SVG图片
          updateSvgImg(type)
          state.canadaFlagLocationSelectedOldIndex = state.canadaFlagLocationSelectedIndex
          break
        case 'usa_flag':
          if (state.usaFlagLocationSelectedOldIndex in [0,1,2,3,4]) {
            banLocation(state.usaFlagLocationList[state.usaFlagLocationSelectedOldIndex].areaName, false)
          }
          banLocation(state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].areaName, true)
          // 清除SVG图片
          clearSvgImg(type)
          // 新增SVG图片
          updateSvgImg(type)
          state.usaFlagLocationSelectedOldIndex = state.usaFlagLocationSelectedIndex
          break
        case 'c_a':
          clearSvgText(type)
          updateSvgColor(type)
          updateSvgTextFont(type)
          state.caLocationSelectedOldIndex = state.caLocationSelectedIndex
          break
      }
    }

    // 获取衣领颜色数量
    const getCollarColorNumber = () => {
      let collarColorList: any = []
      let collarList: any = []
      for (let item of state.collarClassNameData) {
        if (item.type == 0) collarList.push(item)
      }
      let svgClassNameList = collarList[collarList.length - 1].svgClassNameList
      for (let i in svgClassNameList) {
        let classNames: any = document.getElementsByClassName(svgClassNameList[i][0])
        let fill = window
            .getComputedStyle(classNames[0], null)
            .getPropertyValue('fill')
        switch (i) {
          case "0":
            collarColorList.push(fill)
            break
          case "1":
          case "2":
            if (collarColorList[collarColorList.length - 1] != fill) {
              collarColorList.push(fill)
            }
            break
        }
      }
      if (collarColorList.length > 0) state.collarColorNumber = collarColorList.length
    }

    // 选择尺寸大小
    const selecteSize = (type) => {
      switch (type) {
        case 'team_logo':
          updateSvgSize(type)
          break
        case 'number':
          updateSvgSize(type)
          break
        case 'sponsor_logo':
          updateSvgSize(type)
          break
      }
    }

    // 清除SVG文字样式
    const clearSvgText = (type) => {
      let classNameList: any = new Array(0)
      switch (type) {
        case 'number':
          if (state.numberLocationSelectedOldIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13]) {
            classNameList = state.numberLocationList[state.numberLocationSelectedOldIndex].classNameList
          }
          break
        case 'player_name':
          classNameList = state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList
          break
        case 'team_name':
          if (state.teamNameSelecteAreaOld) {
            classNameList = state.teamNameSelecteAreaOld
          }
          break
        case 'sponsor_name':
          if (state.sponsorNameLocationSelectedOldIndex in [0,1,2]) {
            classNameList.push(state.sponsorNameLocationList[state.sponsorNameLocationSelectedOldIndex].className)
          }
          break
        case 'c_a':
          if (state.caLocationSelectedOldIndex in [0,1,2]) {
            classNameList.push(state.caLocationList[state.caLocationSelectedOldIndex].className)
          }
          break
      }
      for (let classItem of classNameList) {
        let text: any = document.getElementsByClassName(classItem)
        for (let item of text) {
          item.innerHTML = ''
          item.style.fill = '#FFFFFF'
          item.style.stroke = 'none'
        }
      }
    }

    const uploadFile = (type) => {
      switch (type) {
        case 'team_logo': {
          if (!(state.teamLogoLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          let file = document.getElementById("team_logo_ip");
          if (file) file.click();
          break }
        case 'sponsor_logo': {
          if (!(state.sponsorLogoLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          let file = document.getElementById("sponsor_logo_ip");
          if (file) file.click();
          break }
      }
    }
    // 获取选中的文件
    const selecteFile = async (e, type) => {
      switch (type) {
        case 'team_logo': {
          state.teamLogoButtonValue = 'Processing...'
          // 上传文件
          let data: any = await svgup(e.target.files[0])
          let files = e.target.value.split('\\')
          e.target.value = ''
          state.teamLogoFileName = files[files.length - 1]
          state.teamLogoImgUrl = data.url
          break }
        case 'sponsor_logo': {
          state.sponsorLogoButtonValue = 'Processing...'
          // 上传文件
          let data: any = await svgup(e.target.files[0])
          let files = e.target.value.split('\\')
          e.target.value = ''
          state.sponsorLogoFileName = files[files.length - 1]
          state.sponsorLogoImgUrl = data.url

          // for (let item of state.operationDataList) {
          //   if (item.type == type) {
          //     // 修改保存的操作数据
          //     item.content = state.sponsorLogoImgUrl
          //     item.fileName = state.sponsorLogoFileName
          //   }
          // }
          break }
      }
      updateSvgImg(type)
    }

    // 清除SVG图片
    const clearSvgImg = (type) => {
      switch (type) {
        case 'team_logo': {
          if (state.teamLogoLocationSelectedOldIndex === '') return
          let classNameOldList: any = state.teamLogoLocationList[state.teamLogoLocationSelectedOldIndex].classNameList
          for (let classNameItem of classNameOldList) {
            let imgClassNames: any = document.getElementsByClassName(classNameItem)
            let logoClassNames: any = document.getElementsByClassName(classNameItem + '_logo')
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
            for (let item of logoClassNames) {
              item.setAttribute('xlink:href', '')
            }
          }
          state.teamLogoLocationSelectedOldIndex = ''
          break }
        case 'sponsor_logo': {
          if (state.sponsorLogoLocationSelectedOldIndex === '') return
          let classNameOldList: any = state.sponsorLogoLocationList[state.sponsorLogoLocationSelectedOldIndex].classNameList
          for (let classNameItem of classNameOldList) {
            let imgClassNames: any = document.getElementsByClassName(classNameItem)
            let logoClassNames: any = document.getElementsByClassName(classNameItem + '_logo')
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
            for (let item of logoClassNames) {
              item.setAttribute('xlink:href', '')
            }
          }
          state.sponsorLogoLocationSelectedOldIndex = ''
          break }
        case 'canada_flag': {
          if (!state.canadaFlagShow && state.canadaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            let className: any = state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].className
            let imgClassNames: any = document.getElementsByClassName(className)
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
          }
          if (state.canadaFlagShow && state.canadaFlagLocationSelectedOldIndex in [0,1,2,3,4]) {
            let classNameOld: any = state.canadaFlagLocationList[state.canadaFlagLocationSelectedOldIndex].className
            let imgClassNames: any = document.getElementsByClassName(classNameOld)
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
          }
          break }
        case 'usa_flag': {
          if (!state.usaFlagShow && state.usaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            let className: any = state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].className
            let imgClassNames: any = document.getElementsByClassName(className)
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
          }
          if (state.usaFlagShow && state.usaFlagLocationSelectedOldIndex in [0,1,2,3,4]) {
            let classNameOld: any = state.usaFlagLocationList[state.usaFlagLocationSelectedOldIndex].className
            let imgClassNames: any = document.getElementsByClassName(classNameOld)
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
          }
          break }
        case 'stop_sign': {
          if (!state.stopSignShow) {
            let className: any = state.stopSignLocationList[state.stopSignLocationSelectedIndex].className
            let imgClassNames: any = document.getElementsByClassName(className)
            for (let item of imgClassNames) {
              item.style.display = 'none'
            }
          }
          break }
      }
    }

    // 更新SVG图片
    const updateSvgImg = (type) => {
      switch (type) {
        case 'team_logo': {
          state.teamLogoButtonValue = 'Upload Logo'
          let classNameList: any = state.teamLogoLocationList[state.teamLogoLocationSelectedIndex].classNameList
          for (let classNameItem of classNameList) {
            let imgClassNames: any = document.getElementsByClassName(classNameItem)
            let logoClassNames: any = document.getElementsByClassName(classNameItem + '_logo')
            for (let item of imgClassNames) {
              item.style.display = 'block'
            }
            for (let item of logoClassNames) {
              item.setAttribute('xlink:href', state.teamLogoImgUrl)
            }
          }
          break }
        case 'sponsor_logo': {
          state.sponsorLogoButtonValue = 'Upload Logo'
          let classNameList: any = state.sponsorLogoLocationList[state.sponsorLogoLocationSelectedIndex].classNameList
          let logoClassNames: any
          for (let classNameItem of classNameList) {
            if (classNameItem === 'img_b_sponsor_logo_1' ||
                classNameItem === 'img_b_sponsor_logo_2' ||
                classNameItem === 'img_b_sponsor_logo_3') {
              logoClassNames = document.getElementsByClassName('sponsor_logo')
            } else {
              logoClassNames = document.getElementsByClassName(classNameItem + '_logo')
            }
            let imgClassNames: any = document.getElementsByClassName(classNameItem)
            for (let item of imgClassNames) {
              item.style.display = 'block'
            }
            for (let item of logoClassNames) {
              item.setAttribute('xlink:href', state.sponsorLogoImgUrl)
            }
          }
          break }
        case 'canada_flag': {
          if (state.canadaFlagShow && state.canadaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            let className: any = state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].className
            let imgClassNames: any = document.getElementsByClassName(className)
            for (let item of imgClassNames) {
              item.style.display = 'block'
            }
          }
          break }
        case 'usa_flag': {
          if (state.usaFlagShow && state.usaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            let className: any = state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].className
            let imgClassNames: any = document.getElementsByClassName(className)
            for (let item of imgClassNames) {
              item.style.display = 'block'
            }
          }
          break }
        case 'stop_sign': {
          if (state.stopSignShow) {
            let className: any = state.stopSignLocationList[state.stopSignLocationSelectedIndex].className
            let imgClassNames: any = document.getElementsByClassName(className)
            for (let item of imgClassNames) {
              item.style.display = 'block'
            }
          }
          break }
      }
    }

    // 获取SVG的尺寸
    const getSvgSize = (type) => {
      switch (type) {
        case 'team_logo': {
          let className: any = state.teamLogoLocationList[state.teamLogoLocationSelectedIndex].classNameList[0]
          let logoClassNames: any = document.getElementsByClassName(className)
          let sizePx = window
              .getComputedStyle(logoClassNames[0], null)
              .getPropertyValue('r')
          // 获取到第一个class
          if (logoClassNames.length > 0) {
            for (let i in state.teamLogoSizeList) {
              if (state.teamLogoSizeList[i].value + 'px' == sizePx) {
                state.teamLogoSizeSelectedIndex = parseInt(i)
                return
              }
            }
          }
          break }
        case 'number': {
          let className: any = state.numberLocationList[state.numberLocationSelectedIndex].classNameList[0]
          let textClassNames: any = document.getElementsByClassName(className)
          let sizePx = window
              .getComputedStyle(textClassNames[0], null)
              .getPropertyValue('font-size')
          // 获取到第一个class
          if (textClassNames.length > 0) {
            for (let i in state.numberSizeList) {
              if (state.numberSizeList[i].value + 'px' == sizePx) {
                state.numberSizeSelectedIndex = parseInt(i)
                return
              }
            }
          }
          break }
        case 'player_name': {
          let className: any = state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList[0]
          let textClassNames: any = document.getElementsByClassName(className)
          let sizePx = window
              .getComputedStyle(textClassNames[0], null)
              .getPropertyValue('font-size')
          // 获取到第一个class
          if (textClassNames.length > 0) {
            for (let i in state.playerNameSizeList) {
              if (state.playerNameSizeList[i].value + 'px' == sizePx) {
                state.playerNameSizeSelectedIndex = parseInt(i)
                return
              }
            }
          }
          break }
        case 'sponsor_logo': {
          let className: any = state.sponsorLogoLocationList[state.sponsorLogoLocationSelectedIndex].classNameList[0]
          let logoClassNames: any = document.getElementsByClassName(className)
          let sizePx = window
              .getComputedStyle(logoClassNames[0], null)
              .getPropertyValue('r')
          // 获取到第一个class
          if (logoClassNames.length > 0) {
            for (let i in state.sponsorLogoSizeList) {
              if (state.sponsorLogoSizeList[i].value + 'px' == sizePx) {
                state.sponsorLogoSizeSelectedIndex = parseInt(i)
                return
              }
            }
          }
          break }
      }
    }

    // 更新SVG图片大小
    const updateSvgSize = (type) => {
      switch (type) {
        case 'team_logo': {
          let classNameList: any = state.teamLogoLocationList[state.teamLogoLocationSelectedIndex].classNameList
          let size: any = state.teamLogoSizeList[state.teamLogoSizeSelectedIndex].value
          for (let classNameItem of classNameList) {
            let classNames: any = document.getElementsByClassName(classNameItem)
            for (let item of classNames) {
              item.style.r = size
            }
          }
          break }
        case 'number': {
          let classNameList: any = state.numberLocationList[state.numberLocationSelectedIndex].classNameList
          let size: any = state.numberSizeList[state.numberSizeSelectedIndex].value
          for (let classNameItem of classNameList) {
            let classNames: any = document.getElementsByClassName(classNameItem)
            for (let item of classNames) {
              item.style.fontSize = size
            }
          }
          break }
        case 'team_name': {
          if (state.teamNameSelecteArea === '') return
          let size = 0
          switch (state.teamNameSelecteArea[0]) {
            case 'team_name_f_center_chest':
              switch (state.teamNameValue.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                  size = 40
                  break
                case 5:
                  size = 38
                  break
                case 6:
                  size = 34
                  break
                case 7:
                  size = 28
                  break
                case 8:
                  size = 26
                  break
                case 9:
                  size = 24
                  break
                case 10:
                  size = 22
                  break
                case 11:
                  size = 18
                  break
                case 12:
                  size = 16
                  break
                case 13:
                  size = 14
                  break
                case 14:
                  size = 12
                  break
              }
              updateSVGClassTextSize(state.teamNameSelecteArea, size)
              break
            case 'team_name_b_upper_back':
            case 'team_name_b_lower_back':
              switch (state.teamNameValue.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                  size = 28
                  break
                case 8:
                  size = 26
                  break
                case 9:
                  size = 24
                  break
                case 10:
                  size = 22
                  break
                case 11:
                  size = 18
                  break
                case 12:
                  size = 16
                  break
                case 13:
                  size = 14
                  break
                case 14:
                  size = 12
                  break
              }
              updateSVGClassTextSize(state.teamNameSelecteArea, size)
              break
          }
          break
        }
        case 'sponsor_name': {
          if (state.sponsorNameLocationSelectedIndex === '') return
          let size = 0
          switch (state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className) {
            case 'sponsor_name_b_upper_back':
            case 'team_name_b_lower_back':
              switch (state.sponsorNameValue.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                  size = 28
                  break
                case 8:
                  size = 26
                  break
                case 9:
                  size = 24
                  break
                case 10:
                  size = 22
                  break
                case 11:
                  size = 18
                  break
                case 12:
                  size = 16
                  break
                case 13:
                  size = 14
                  break
                case 14:
                  size = 12
                  break
              }
              updateSVGClassTextSize([state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className], size)
              break
          }
          break
        }
        case 'player_name': {
          let size = 0
          switch (state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList[0]) {
            case 'number_b_player_name':
              switch (state.playerNameValue.length) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                  size = 28
                  break
                case 8:
                  size = 26
                  break
                case 9:
                  size = 24
                  break
                case 10:
                  size = 22
                  break
                case 11:
                  size = 18
                  break
                case 12:
                  size = 16
                  break
                case 13:
                  size = 14
                  break
                case 14:
                  size = 12
                  break
              }
              updateSVGClassTextSize(state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList, size)
              break
          }
          break
        }
        case 'sponsor_logo': {
          let classNameList: any = state.sponsorLogoLocationList[state.sponsorLogoLocationSelectedIndex].classNameList
          let size: any = state.sponsorLogoSizeList[state.sponsorLogoSizeSelectedIndex].value
          for (let classNameItem of classNameList) {
            let classNames: any = document.getElementsByClassName(classNameItem)
            for (let item of classNames) {
              item.style.r = size
            }
          }
          break }
      }
    }

    const updateSVGClassTextSize = (classNameList, size) => {
      for (let classNameItem of classNameList) {
        let classNames: any = document.getElementsByClassName(classNameItem)
        for (let item of classNames) {
          item.style.fontSize = size
        }
      }
    }

    // 选择颜色数量
    const selecteColorNumber = (type) => {
      switch (type) {
        case 'collar':
          state.collarColorList = []
          state.collarColorNameList = []
          let classNameList: any = state.collarClassNameData[state.collarColorNumber - 1].svgClassNameList
          for (let i = 0; i < Object.getOwnPropertyNames(classNameList).length; i++) {
            // 查询颜色值与颜色名称
            let colorHex = getSvgColorHex(classNameList[i][0], 'fill')
            let colorName = getColorNameByColor(colorHex)
            state.collarColorList.push(colorHex)
            state.collarColorNameList.push(colorName)
          }
          if (state.collarColorSelectedIndex >= state.collarColorNumber) {
            state.collarColorSelectedIndex = -1
            state.ysshow = 0
          }
          updateSvgColor('collar')
          break
        case 'number': {
          if (state.numberLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13]) {
            let classNameList: any = state.numberLocationList[state.numberLocationSelectedIndex].classNameList
            for (let i in state.numberColor) {
              for (let classNameItem of classNameList) {
                // 更新SVG颜色
                if (i == '0') {
                  let team: any = document.getElementsByClassName(classNameItem)
                  for (let item of team) {
                    item.style.stroke = state.numberColorNumber >= parseInt(i) + 1 ? state.numberColor[i]: 'none'
                  }
                } else {
                  let team: any = document.getElementsByClassName(classNameItem + '_' + i)
                  for (let item of team) {
                    item.style.stroke = state.numberColorNumber >= parseInt(i) + 1 ? state.numberColor[i]: 'none'
                  }
                }
                // 更新操作栏颜色
                if (parseInt(i) > 0 && parseInt(i) >= state.numberColorNumber) {
                  state.numberColor[parseInt(i)] = state.numberColor[parseInt(i) - 1]
                  state.numberColorName[parseInt(i)] = state.numberColorName[parseInt(i) - 1]
                }
              }
            }
          }
          // 更新操作栏选中状态
          if (state.numberColorSelectedIndex + 1 > state.numberColorNumber) {
            state.numberColorSelectedIndex = -1
            state.ysshow = 0
          }
          updateSvgColor(type)
          break }
        case 'player_name': {
          if (!state.playerNameLocationList[state.playerNameLocationSelectedIndex].disabled &&
              state.playerNameColorNumber in [0,1,2,3]) {
            let classNameList: any = state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList
            for (let i in state.playerNameColor) {
              for (let classNameItem of classNameList) {
                // 更新SVG颜色
                if (i == '0') {
                  let team: any = document.getElementsByClassName(classNameItem)
                  for (let item of team) {
                    item.style.stroke = state.playerNameColorNumber >= parseInt(i) + 1 ? state.playerNameColor[i]: 'none'
                  }
                } else {
                  let team: any = document.getElementsByClassName(classNameItem + '_' + i)
                  for (let item of team) {
                    item.style.stroke = state.playerNameColorNumber >= parseInt(i) + 1 ? state.playerNameColor[i]: 'none'
                  }
                }
                // 更新操作栏颜色
                if (parseInt(i) > 0 && parseInt(i) >= state.playerNameColorNumber) {
                  state.playerNameColor[parseInt(i)] = state.playerNameColor[parseInt(i) - 1]
                  state.playerNameColorName[parseInt(i)] = state.playerNameColorName[parseInt(i) - 1]
                }
              }
            }
            updateSvgTextFont(type)
            updateSvgColor(type)
          }
          // 更新操作栏选中状态
          if (state.playerNameColorSelectedIndex + 1 > state.playerNameColorNumber) {
            state.playerNameColorSelectedIndex = -1
            state.ysshow = 0
          }
          break }
        case 'team_name': {
          for (let i in state.teamNameNameColor) {
            // 更新SVG颜色
            if (i == '0') {
              let team: any = document.getElementsByClassName(state.teamNameSelecteArea[0])
              for (let item of team) {
                item.style.stroke = state.teamNameColorNumber >= parseInt(i) + 1 ? state.teamNameNameColor[i]: 'none'
              }
            } else {
              let team: any = document.getElementsByClassName(state.teamNameSelecteArea[0] + '_' + i)
              for (let item of team) {
                item.style.stroke = state.teamNameColorNumber >= parseInt(i) + 1 ? state.teamNameNameColor[i]: 'none'
              }
            }
            // 更新操作栏颜色
            if (parseInt(i) > 0 && parseInt(i) >= state.teamNameColorNumber) {
              state.teamNameNameColor[parseInt(i)] = state.teamNameNameColor[parseInt(i) - 1]
              state.teamNameNameColorName[parseInt(i)] = state.teamNameNameColorName[parseInt(i) - 1]
            }
          }
          // 更新操作栏选中状态
          if (state.teamNameColorSelectedIndex + 1 > state.teamNameColorNumber) {
            state.teamNameColorSelectedIndex = -1
            state.ysshow = 0
          }
          break }
        case 'sponsor_name': {
          if (state.sponsorNameLocationSelectedIndex in [0,1,2,3] && state.sponsorNameColorNumber in [0,1,2,3]) {
            for (let i in state.sponsorNameColor) {
              let className: any = state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className
              // 更新SVG颜色
              if (i == '0') {
                let team: any = document.getElementsByClassName(className)
                for (let item of team) {
                  item.style.stroke = state.sponsorNameColorNumber >= parseInt(i) + 1 ? state.sponsorNameColor[i]: 'none'
                }
              } else {
                let team: any = document.getElementsByClassName(className + '_' + i)
                for (let item of team) {
                  item.style.stroke = state.sponsorNameColorNumber >= parseInt(i) + 1 ? state.sponsorNameColor[i]: 'none'
                }
              }
              // 更新操作栏颜色
              if (parseInt(i) > 0 && parseInt(i) >= state.sponsorNameColorNumber) {
                state.sponsorNameColor[parseInt(i)] = state.sponsorNameColor[parseInt(i) - 1]
                state.sponsorNameColorName[parseInt(i)] = state.sponsorNameColorName[parseInt(i) - 1]
              }
            }
            updateSvgTextFont(type)
            updateSvgColor(type)
          }
          // 更新操作栏选中状态
          if (state.sponsorNameColorSelectedIndex + 1 > state.sponsorNameColorNumber) {
            state.sponsorNameColorSelectedIndex = -1
            state.ysshow = 0
          }
          break }
        case 'c_a': {
          if (state.caLocationSelectedIndex in [0,1,2]) {
            let className: any = state.caLocationList[state.caLocationSelectedIndex].className
            for (let i in state.caColor) {
              // 更新SVG颜色
              if (i == '0') {
                let team: any = document.getElementsByClassName(className)
                for (let item of team) {
                  item.style.stroke = state.caColorNumber >= parseInt(i) + 1 ? state.caColor[i]: 'none'
                }
              } else {
                let team: any = document.getElementsByClassName(className + '_' + i)
                for (let item of team) {
                  item.style.stroke = state.caColorNumber >= parseInt(i) + 1 ? state.caColor[i]: 'none'
                }
              }
              // 更新操作栏颜色
              if (parseInt(i) > 0 && parseInt(i) >= state.caColorNumber) {
                state.caColor[parseInt(i)] = state.caColor[parseInt(i) - 1]
                state.caColorName[parseInt(i)] = state.caColorName[parseInt(i) - 1]
              }
            }
          }
          // 更新操作栏选中状态
          if (state.caColorSelectedIndex + 1 > state.caColorNumber) {
            state.caColorSelectedIndex = -1
            state.ysshow = 0
          }
          updateSvgColor(type)
          break}
      }

    }

    // 更改SVG文字
    const updateSvgText = (type) => {
      switch (type) {
        case 'c_a': {
          if (state.caLocationSelectedIndex in [0,1,2] && state.caColorNumber in [0,1,2]) {
            let className = state.caLocationList[state.caLocationSelectedIndex].className
            let text: any = document.getElementsByClassName(className)
            for (let item of text) {
              item.innerHTML = state.caValue
            }
          }
          break}
      }
    }

    // 更改SVG字体
    const updateSvgTextFont = (type) => {
      switch (type) {
        case 'number': {
          if (state.numberLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13]) {
            for (let classItem of state.numberLocationList[state.numberLocationSelectedIndex].classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              // 设置字体
              for (let item of text) {
                item.style.fontFamily = state.numberFamily
              }
            }
          }
          break }
        case 'player_name': {
          for (let classItem of state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList) {
            let text: any = document.getElementsByClassName(classItem)
            // 设置字体
            for (let item of text) {
              item.style.fontFamily = state.playerNameFamily
            }
          }
          break }
        case 'team_name': {
          if (state.teamNameSelecteArea) {
            for (let classItem of state.teamNameSelecteArea) {
              let text: any = document.getElementsByClassName(classItem)
              // 设置字体
              for (let item of text) {
                item.style.fontFamily = state.teamNameFamily
              }
            }
          }
          break }
        case 'sponsor_name': {
          let className = state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className
          let text: any = document.getElementsByClassName(className)
          // 设置字体
          for (let item of text) {
            item.style.fontFamily = state.sponsorNameFamily
          }
          break }
        case 'c_a': {
          if (state.caLocationSelectedIndex in [0,1,2]) {
            let className = state.caLocationList[state.caLocationSelectedIndex].className
            let text: any = document.getElementsByClassName(className)
            // 设置字体
            for (let item of text) {
              item.style.fontFamily = state.caFamily
            }
          }
          break}
      }
    }

    const switchMenu = (e: any) => {
      // 显示back按钮
      state.showBack = true
      state.show = e
      if (e == 2) {
        state.ysshow = 1
      }
      if (e == 'flags_stop_signs') {
        for (let item of state.operationDataList) {
          if (item.type == 'canada_flag') {
            state.canadaFlagShow = true
            state.canadaFlagLocationSelectedOldIndex = item.locationIndex
            state.canadaFlagLocationSelectedIndex = item.locationIndex
            state.canadaFlagApplication = item.application
            delFlagOperation(item.type)
          }
        }
        for (let item of state.operationDataList) {
          if (item.type == 'usa_flag') {
            state.usaFlagShow = true
            state.usaFlagLocationSelectedOldIndex = item.locationIndex
            state.usaFlagLocationSelectedIndex = item.locationIndex
            state.usaFlagApplication = item.application
            delFlagOperation(item.type)
          }
        }
        for (let item of state.operationDataList) {
          if (item.type == 'stop_sign') {
            state.stopSignShow = true
            state.stopSignLocationSelectedIndex = item.locationIndex
            state.stopSignApplication = item.application
            delFlagOperation(item.type)
          }
        }
      }
    }

    const delFlagOperation = (type) => {
      // 取出需要修改的操作项
      let delIndex = -1 as any
      for (let i in state.operationDataList) {
        if (state.operationDataList[i].type == type) {
          delIndex = i
        }
      }
      // 移除编辑项
      if (delIndex != -1) {
        state.operationDataList.splice(delIndex, 1)
      }
    }

    // 输入Text
    const inputText = (type) => {
      switch (type) {
        case 'number': {
          state.numberValue = state.numberValue.replace(/[^0-9]/gi, '') // 以0开头或者输入非数字，会被替换成空
          if (state.numberColorNumber in [0,1,2,3]) {
            let classNameList: any = new Array(0)

            if (state.numberLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13]) {
              let classNames: any = state.numberLocationList[state.numberLocationSelectedIndex].classNameList
              for (let classNameItem of classNames) {
                classNameList.push(classNameItem)
              }
            }

            for (let item of state.operationDataList) {
              if (item.type == type) {
                // 修改保存的操作数据
                item.value = state.numberValue
                for (let classNameItem of item.className) {
                  classNameList.push(classNameItem)
                }
              }
            }

            // 修改SVG的文字
            for (let classItem of classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              for (let item of text) {
                item.innerHTML = state.numberValue
              }
            }
          }
          break }
        case 'player_name': {
          if (!state.playerNameLocationList[state.playerNameLocationSelectedIndex].disabled &&
              state.playerNameColorNumber in [0,1,2,3]) {
            updateSvgColor(type)
            updateSvgTextFont(type)
          }
          updateSvgSize(type)
          break }
        case 'team_name': {
          let classNameList: any = new Array(0)
          if (state.teamNameSelecteArea) {
            classNameList.push(state.teamNameSelecteArea[0])
          }
          // for (let item of state.operationDataList) {
          //   if (item.type == type) {
          //     // 修改保存的操作数据
          //     item.value = state.teamNameValue
          //     classNameList.push(item.className[0])
          //   }
          // }
          // 修改SVG的文字
          for (let classItem of classNameList) {
            let text: any = document.getElementsByClassName(classItem)
            for (let item of text) {
              item.innerHTML = state.teamNameValue
            }
          }
          updateSvgSize(type)
          break }
        case 'sponsor_name': {
          let classNameList: any = new Array(0)
          if (state.sponsorNameLocationSelectedIndex in [0,1,2,3]) {
            classNameList.push(state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className)
          }

          // for (let item of state.operationDataList) {
          //   if (item.type == type) {
          //     // 修改保存的操作数据
          //     item.value = state.sponsorNameValue
          //     classNameList.push(item.className)
          //   }
          // }

          // 修改SVG的文字
          for (let classItem of classNameList) {
            let text: any = document.getElementsByClassName(classItem)
            for (let item of text) {
              item.innerHTML = state.sponsorNameValue
            }
          }
          updateSvgSize(type)
          break }
      }
    }

    // 切换操作栏的颜色栏
    const switchColor = (type, areaId, index) => {
      state.ysshow = 1
      switch (type) {
        case 'collar':
          state.collarColorSelectedIndex = index
          break
        case 'collar_underlay':
          state.collarUnderlayColorSelectedIndex = index
          break
        case 'collar_lace':
          state.collarLaceColorSelectedIndex = index
          break
        case 'body_color':
          state.bodyColorSelectedIndex = index
          break
        case 'number':
          state.numberColorSelectedIndex = index
          break
        case 'player_name':
          state.playerNameColorSelectedIndex = index
          break
        case 'team_name':
          state.teamNameColorSelectedIndex = index
          break
        case 'sponsor_name':
          state.sponsorNameColorSelectedIndex = index
          break
        case 'c_a':
          state.caColorSelectedIndex = index
          break
      }

      // 更新颜色选择栏的选中标记
      for (let i in state.colorList) {
        // 初始化操作栏颜色选中
        initSwitchColor(type)

        switch (type) {
          case 'collar':
            if (state.collarColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.collarColorList[state.collarColorSelectedIndex]) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'collar_underlay':
            if (state.collarUnderlayColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.collarUnderlayColor) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'collar_lace':
            if (state.collarLaceColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.collarLaceColor) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'body_color':
            if (state.bodyColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.bodyColorList[state.bodyColorSelectedIndex].color) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'number':
            if (state.numberColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.numberColor[state.numberColorSelectedIndex]) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'player_name':
            if (state.playerNameColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.playerNameColor[state.playerNameColorSelectedIndex]) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'team_name':
            if (state.teamNameColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.teamNameNameColor[state.teamNameColorSelectedIndex]) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'sponsor_name':
            if (state.sponsorNameColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.sponsorNameColor[state.sponsorNameColorSelectedIndex]) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
          case 'c_a':
            if (state.caColorSelectedIndex != -1) {
              if (state.colorList[i].colorHexadecimal == state.caColor[state.caColorSelectedIndex]) {
                state.colorSelectedIndex = parseInt(i)
              }
            }
            break
        }
      }
    }

    // 初始化操作栏颜色选中
    const initSwitchColor = (type) => {
      state.collarColorSelectedIndex = type == 'collar' ? state.collarColorSelectedIndex : -1
      state.collarUnderlayColorSelectedIndex = type == 'collar_underlay' ? state.collarUnderlayColorSelectedIndex : -1
      state.collarLaceColorSelectedIndex = type == 'collar_lace' ? state.collarLaceColorSelectedIndex : -1
      state.bodyColorSelectedIndex = type == 'body_color' ? state.bodyColorSelectedIndex : -1
      state.numberColorSelectedIndex = type == 'number' ? state.numberColorSelectedIndex : -1
      state.playerNameColorSelectedIndex = type == 'player_name' ? state.playerNameColorSelectedIndex : -1
      state.teamNameColorSelectedIndex = type == 'team_name' ? state.teamNameColorSelectedIndex : -1
      state.sponsorNameColorSelectedIndex = type == 'sponsor_name' ? state.sponsorNameColorSelectedIndex : -1
      state.caColorSelectedIndex = type == 'c_a' ? state.caColorSelectedIndex : -1
    }

    // 返回样式
    const rollBack = (type) => {
      state.show = 0
      state.ysshow = 0
      switch (type) {
        case 'team_logo':
          // 清除SVG内容
          clearSvgImg(type)
          // 初始化数据
          initOperationData(type, false)
          break
        case 'number':
          state.show = 0
          state.ysshow = 0
          // 清除SVG内容
          if (state.numberLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13]) {
            let classNameList: any = state.numberLocationList[state.numberLocationSelectedIndex].classNameList
            for (let classItem of classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              for (let item of text) {
                item.innerHTML = ''
              }
            }
          }
          // 初始化数据
          initOperationData(type, false)
          break
        case 'player_name':
          // 清除SVG内容
          if (!state.playerNameLocationList[state.playerNameLocationSelectedIndex].disabled &&
              state.playerNameColorNumber in [0,1,2,3]) {
            let classNameList: any = state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList
            for (let classItem of classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              for (let item of text) {
                item.innerHTML = ''
              }
            }
          }
          // 初始化数据
          initOperationData(type, false)
          break
        case 'team_name':
          // 清除SVG内容
          if (state.teamNameSelecteArea) {
            for (let classItem of state.teamNameSelecteArea) {
              let text: any = document.getElementsByClassName(classItem)
              for (let item of text) {
                item.innerHTML = ''
              }
            }
          }
          // 初始化数据
          initOperationData(type, false)
          break
        case 'sponsor_name':
          // 清除SVG内容
          if (state.sponsorNameLocationSelectedIndex in [0,1,2,3] && state.sponsorNameColorNumber in [0,1,2,3]) {
            let className: any = state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className
            let text: any = document.getElementsByClassName(className)
            for (let item of text) {
              item.innerHTML = ''
            }
          }
          // 初始化数据
          initOperationData(type, false)
          break
        case 'sponsor_logo':
          // 清除SVG内容
          clearSvgImg(type)
          // 初始化数据
          initOperationData(type, false)
          break
        case 'flags_stop_signs':
          state.show = 0
          state.ysshow = 0
          // 清除SVG内容
          initOperationData('canada_flag', false)
          initOperationData('usa_flag', false)
          initOperationData('stop_sign', false)
          break
        case 'c_a':
          // 清除SVG内容
          if (state.caLocationSelectedIndex in [0,1,2]) {
            let className: any = state.caLocationList[state.caLocationSelectedIndex].className
            let text: any = document.getElementsByClassName(className)
            for (let item of text) {
              item.innerHTML = ''
            }
          }
          // 初始化数据
          initOperationData(type, false)
          break
      }
    }

    // 初始化操作栏数据
    const initOperationData = (type, isSeve) => {
      switch (type) {
        case 'team_logo': {
          state.teamLogoApplication = state.teamLogoApplicationList[0].value
          state.teamLogoLocationSelectedOldIndex = ''
          state.teamLogoLocationSelectedIndex = ''
          state.teamLogoSizeSelectedIndex = ''
          state.teamLogoImgUrl = ''
          state.teamLogoFileName = 'No File selected'
          break }
        case 'number': {
          state.numberApplication = state.numberApplicationList[0].value
          state.numberLocationSelectedOldIndex = ''
          state.numberLocationSelectedIndex = ''
          state.numberSizeSelectedIndex = ''
          state.numberFamily = state.textFontList[0].dictValue
          state.numberColorNumber = ''
          state.numberColorSelectedIndex = -1
          state.numberColor[0] = state.bodyMainContraryColor
          state.numberColor[1] = state.bodyMainContraryColor
          state.numberColor[2] = state.bodyMainContraryColor
          state.numberColorName[0] = state.bodyMainContraryColorName
          state.numberColorName[1] = state.bodyMainContraryColorName
          state.numberColorName[2] = state.bodyMainContraryColorName
          state.numberValue = isSeve ? state.numberValue : '28'
          break }
        case 'player_name': {
          state.playerNameApplication = state.playerNameApplicationList[0].value
          state.playerNameFamily = state.textFontList[0].dictValue
          state.playerNameColorNumber = ''
          state.playerNameColorSelectedIndex = -1
          state.playerNameColor[0] = state.bodyMainContraryColor
          state.playerNameColor[1] = state.bodyMainContraryColor
          state.playerNameColor[2] = state.bodyMainContraryColor
          state.playerNameColorName[0] = state.bodyMainContraryColorName
          state.playerNameColorName[1] = state.bodyMainContraryColorName
          state.playerNameColorName[2] = state.bodyMainContraryColorName
          state.playerNameValue = 'PLAYER'
          break }
        case 'team_name': {
          state.teamNameApplication = state.teamNameApplicationList[0].value
          state.teamNameSelecteAreaOld = ''
          state.teamNameSelecteArea = ''
          state.teamNameFamily = state.textFontList[0].dictValue
          state.teamNameColorNumber = ''
          state.teamNameColorSelectedIndex = -1
          state.teamNameNameColor[0] = state.bodyMainContraryColor
          state.teamNameNameColor[1] = state.bodyMainContraryColor
          state.teamNameNameColor[2] = state.bodyMainContraryColor
          state.teamNameNameColorName[0] = state.bodyMainContraryColorName
          state.teamNameNameColorName[1] = state.bodyMainContraryColorName
          state.teamNameNameColorName[2] = state.bodyMainContraryColorName
          state.teamNameValue = isSeve ? state.teamNameValue : 'MY TEAM'
          break }
        case 'sponsor_name': {
          state.sponsorNameApplication = state.sponsorNameApplicationList[0].value
          state.sponsorNameLocationSelectedOldIndex = ''
          state.sponsorNameLocationSelectedIndex = ''
          state.sponsorNameFamily = state.textFontList[0].dictValue
          state.sponsorNameColorNumber = ''
          state.sponsorNameColorSelectedIndex = -1
          state.sponsorNameColor[0] = state.bodyMainContraryColor
          state.sponsorNameColor[1] = state.bodyMainContraryColor
          state.sponsorNameColor[2] = state.bodyMainContraryColor
          state.sponsorNameColorName[0] = state.bodyMainContraryColorName
          state.sponsorNameColorName[1] = state.bodyMainContraryColorName
          state.sponsorNameColorName[2] = state.bodyMainContraryColorName
          state.sponsorNameValue = 'SPONSOR'
          break }
        case 'sponsor_logo': {
          state.sponsorLogoApplication = state.sponsorLogoApplicationList[0].value
          state.sponsorLogoLocationSelectedOldIndex = ''
          state.sponsorLogoLocationSelectedIndex = ''
          state.sponsorLogoSizeSelectedIndex = ''
          state.sponsorLogoImgUrl = ''
          state.sponsorLogoFileName = 'No File selected'
          break }
        case 'canada_flag': {
          if (!isSeve) {
            state.canadaFlagShow = false
            if (state.canadaFlagLocationSelectedOldIndex in [0,1,2,3,4]) {
              banLocation(state.canadaFlagLocationList[state.canadaFlagLocationSelectedOldIndex].areaName, false)
            }
            if (state.canadaFlagLocationSelectedIndex in [0,1,2,3,4]) {
              banLocation(state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].areaName, false)
            }
            clearSvgImg('canada_flag')
            state.canadaFlagLocationSelectedOldIndex = ''
            state.canadaFlagLocationSelectedIndex = ''
            state.canadaFlagApplication = state.flagApplicationList[0].value
          }
          break }
        case 'usa_flag': {
          if (!isSeve) {
            state.usaFlagShow = false
            if (state.usaFlagLocationSelectedOldIndex in [0,1,2,3,4]) {
              banLocation(state.usaFlagLocationList[state.usaFlagLocationSelectedOldIndex].areaName, false)
            }
            if (state.usaFlagLocationSelectedIndex in [0,1,2,3,4]) {
              banLocation(state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].areaName, false)
            }
            clearSvgImg('usa_flag')
            state.usaFlagLocationSelectedOldIndex = ''
            state.usaFlagLocationSelectedIndex = ''
            state.usaFlagApplication = state.flagApplicationList[0].value
          }
          break }
        case 'stop_sign': {
          if (!isSeve) {
            state.stopSignShow = false
            clearSvgImg('stop_sign')
            if (!state.stopSignSwitchDisabled) {
              // 取消禁用该位置
              banLocation(state.stopSignLocationList[state.stopSignLocationSelectedIndex].areaName, false)
            }
            state.stopSignApplication = state.flagApplicationList[0].value
          }
          break }
        case 'c_a': {
          state.caApplication = state.caApplicationList[0].value
          state.caLocationSelectedOldIndex = ''
          state.caLocationSelectedIndex = ''
          state.caFamily = state.textFontList[0].dictValue
          state.caColorNumber = ''
          state.caColorSelectedIndex = -1
          state.caColor[0] = state.bodyMainContraryColor
          state.caColor[1] = state.bodyMainContraryColor
          state.caColor[2] = state.bodyMainContraryColor
          state.caColorName[0] = state.bodyMainContraryColorName
          state.caColorName[1] = state.bodyMainContraryColorName
          state.caColorName[2] = state.bodyMainContraryColorName
          state.caValue = ''
          break }
      }
    }

    // 禁止相关位置
    const banLocation = (location, disabled) => {
      banLocationItem(location, disabled, state.teamLogoLocationList)
      banLocationItem(location, disabled, state.numberLocationList)
      banLocationItem(location, disabled, state.playerNameLocationList)
      banLocationItem(location, disabled, state.teamNameAreaList)
      banLocationItem(location, disabled, state.sponsorNameLocationList)
      banLocationItem(location, disabled, state.sponsorLogoLocationList)
      banLocationItem(location, disabled, state.canadaFlagLocationList)
      banLocationItem(location, disabled, state.usaFlagLocationList)
      banLocationItem(location, disabled, state.stopSignLocationList)
      banLocationItem(location, disabled, state.caLocationList)

      if (!state.stopSignShow) {
        if (state.stopSignLocationList[state.stopSignLocationSelectedIndex].disabled) {
          state.stopSignSwitchDisabled = true
        } else {
          state.stopSignSwitchDisabled = false
        }
      }
    }

    const banLocationItem = (location, disabled, locationList) => {
      for (let item of locationList) {
        if (location === "Sleeves") {
          for (let item1 of locationList) {
            if (item1.areaName === "Sleeves" ||
                item1.areaName === "Left Sleeve" ||
                item1.areaName === "Right Sleeve") {
              item1.disabled = disabled
            }
          }
        } else if (location === "Left Sleeve") {
          if (disabled) {
            for (let item1 of locationList) {
              if (item1.areaName === "Sleeves" ||
                  item1.areaName === "Left Sleeve") {
                item1.disabled = disabled
              }
            }
          } else {
            let leftSleeve = false
            let rightSleeve = false
            for (let item1 of locationList) {
              if (item1.areaName === "Left Sleeve") {
                item1.disabled = disabled
                leftSleeve = item1.disabled
              }
              if (item1.areaName === "Right Sleeve") {
                rightSleeve = item1.disabled
              }
            }
            for (let item2 of locationList) {
              if (item2.areaName === "Sleeves" && !leftSleeve && !rightSleeve) {
                item2.disabled = disabled
              }
            }
          }
        } else if (location === "Right Sleeve") {
          if (disabled) {
            for (let item1 of locationList) {
              if (item1.areaName === "Sleeves" ||
                  item1.areaName === "Right Sleeve") {
                item1.disabled = disabled
              }
            }
          } else {
            let leftSleeve = false
            let rightSleeve = false
            for (let item1 of locationList) {
              if (item1.areaName === "Left Sleeve") {
                leftSleeve = item1.disabled
              }
              if (item1.areaName === "Right Sleeve") {
                item1.disabled = disabled
                rightSleeve = item1.disabled
              }
            }
            for (let item2 of locationList) {
              if (item2.areaName === "Sleeves" && !leftSleeve && !rightSleeve) {
                item2.disabled = disabled
              }
            }
          }
        } else if (location === "Shoulders") {
          for (let item1 of locationList) {
            if (item1.areaName === "Shoulders" ||
                item1.areaName === "Left Shoulder" ||
                item1.areaName === "Right Shoulder") {
              item1.disabled = disabled
            }
          }
        } else if (location === "Left Shoulder") {
          if (disabled) {
            for (let item1 of locationList) {
              if (item1.areaName === "Shoulders" ||
                  item1.areaName === "Left Shoulder") {
                item1.disabled = disabled
              }
            }
          } else {
            let leftShoulder = false
            let rightShoulder = false
            for (let item1 of locationList) {
              if (item1.areaName === "Left Shoulder") {
                item1.disabled = disabled
                leftShoulder = item1.disabled
              }
              if (item1.areaName === "Right Shoulder") {
                rightShoulder = item1.disabled
              }
            }
            for (let item2 of locationList) {
              if (item2.areaName === "Shoulders" && !leftShoulder && !rightShoulder) {
                item2.disabled = disabled
              }
            }
          }
        } else if (location === "Right Shoulder") {
          if (disabled) {
            for (let item1 of locationList) {
              if (item1.areaName === "Shoulders" ||
                  item1.areaName === "Right Shoulder") {
                item1.disabled = disabled
              }
            }
          } else {
            let leftShoulder = false
            let rightShoulder = false
            for (let item1 of locationList) {
              if (item1.areaName === "Left Shoulder") {
                leftShoulder = item1.disabled
              }
              if (item1.areaName === "Right Shoulder") {
                item1.disabled = disabled
                rightShoulder = item1.disabled
              }
            }
            for (let item2 of locationList) {
              if (item2.areaName === "Shoulders" && !leftShoulder && !rightShoulder) {
                item2.disabled = disabled
              }
            }
          }
        } else {
          if (item.areaName == location) {
            item.disabled = disabled
          }
        }
      }
    }

    // 获取SVG里class的颜色
    const getSvgColorHex = (className, property) => {
      let classNames: any = document.getElementsByClassName(className)
      return colorRbgToHex(
          window
              .getComputedStyle(classNames[0], null)
              .getPropertyValue(property)
      )
    }

    // 根据颜色获取颜色名称
    const getColorNameByColor = (colorHex) => {
      for (let item of state.colorList) {
        if (item.colorHexadecimal == colorHex) {
           return item.colorName
        }
      }
      return 'OTHER'
    }

    // RGB转16进制
    const colorRbgToHex = (color) => {
      var that = color
      //十六进制颜色值的正则表达式
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      // 如果是rgb颜色表示
      if (/^(rgb|RGB)/.test(that)) {
        var aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',')
        var strHex = '#'
        for (var i = 0; i < aColor.length; i++) {
          var hex = Number(aColor[i]).toString(16).toUpperCase()
          if (hex.length < 2) {
            hex = '0' + hex
          }
          strHex += hex
        }
        if (strHex.length !== 7) {
          strHex = that
        }
        return strHex
      } else if (reg.test(that)) {
        var aNum = that.replace(/#/, '').split('')
        if (aNum.length === 6) {
          return that
        } else if (aNum.length === 3) {
          var numHex = '#'
          for (var i = 0; i < aNum.length; i += 1) {
            numHex += aNum[i] + aNum[i]
          }
          return numHex
        }
      }
      return that
    }

    // 选择颜色,更新操作栏颜色
    const selecteColor = (index) => {
      state.colorSelectedIndex = index
      // Collar Color
      if (state.collarColorSelectedIndex != -1) {
        state.collarColorList[state.collarColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.collarColorNameList[state.collarColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('collar')
      }
      // Collar Underlay
      if (state.collarUnderlayColorSelectedIndex != -1) {
        state.collarUnderlayColor = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.collarUnderlayColorName = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('collar_underlay')
      }
      // Collar Lace
      if (state.collarLaceColorSelectedIndex != -1) {
        state.collarLaceColor = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.collarLaceColorName = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('collar_lace')
      }
      // Body Color
      if (state.bodyColorSelectedIndex != -1) {
        state.bodyColorList[state.bodyColorSelectedIndex].color = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.bodyColorList[state.bodyColorSelectedIndex].colorName = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('body_color')
      }
      // Number
      if (state.numberColorSelectedIndex != -1) {
        state.numberColor[state.numberColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.numberColorName[state.numberColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('number')
      }
      // Player Name
      if (state.playerNameColorSelectedIndex != -1) {
        state.playerNameColor[state.playerNameColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.playerNameColorName[state.playerNameColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorName
        if (!state.playerNameLocationList[state.playerNameLocationSelectedIndex].disabled &&
            state.playerNameColorNumber in [0,1,2,3]) {
          updateSvgColor('player_name')
        }
      }
      // Team Name
      if (state.teamNameColorSelectedIndex != -1) {
        state.teamNameNameColor[state.teamNameColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.teamNameNameColorName[state.teamNameColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('team_name')
      }
      // Sponsor Name
      if (state.sponsorNameColorSelectedIndex != -1) {
        state.sponsorNameColor[state.sponsorNameColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.sponsorNameColorName[state.sponsorNameColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorName
        if (state.sponsorNameLocationSelectedIndex in [0,1,2,3] && state.sponsorNameColorNumber in [0,1,2,3]) {
          updateSvgColor('sponsor_name')
        }
      }
      // C A
      if (state.caColorSelectedIndex != -1) {
        state.caColor[state.caColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorHexadecimal
        state.caColorName[state.caColorSelectedIndex] = state.colorList[state.colorSelectedIndex].colorName
        updateSvgColor('c_a')
      }

    }

    // 更新SVG隐藏与显示
    const updateSvgDisplay = (oldClassName, className) => {
      let oldClassNames: any = document.getElementsByClassName(oldClassName)
      let classNames: any = document.getElementsByClassName(className)
      // 隐藏旧样式
      for (let item of oldClassNames) {
        item.style.display = 'none'
      }
      // 显示新样式
      for (let item of classNames) {
        item.style.display = 'block'
      }
    }

    // 获取当前衣领类型
    const getSvgCollarTypeSelectedIndex = () => {
      for (let i in state.collarTypeList) {
        let classNames: any = document.getElementsByClassName(state.collarTypeList[i].className)

        if (classNames.length > 0) {
          let display = window
              .getComputedStyle(classNames[0], null)
              .getPropertyValue('display')
          if (display == 'block') {
            state.collarTypeSelectedInitialIndex = parseInt(i)
            state.collarTypeSelectedOldIndex = parseInt(i)
            state.collarTypeSelectedIndex = parseInt(i)
          }
        }
      }
      selecteLocation('collar')
    }

    // 更新SVG颜色
    const updateSvgColor = (type) => {
      switch (type) {
        case 'collar':
          let classNameListObj: any = state.collarClassNameData[state.collarColorNumber - 1].svgClassNameList
          for (let i = 0; i < Object.getOwnPropertyNames(classNameListObj).length; i++) {
            for (let item of classNameListObj[i]) {
              let classNames: any = document.getElementsByClassName(item)
              for (let classItem of classNames) {
                classItem.style.fill = state.collarColorList[i]
              }
            }
          }
          break
        case 'collar_underlay':
          if (state.collarUnderlayClassName) {
            let classNames: any = document.getElementsByClassName(state.collarUnderlayClassName)
            for (let item of classNames) {
              item.style.fill = state.collarUnderlayColor
            }
          }
          break
        case 'collar_lace':
          if (state.collarLaceClassName) {
            let classNames: any = document.getElementsByClassName(state.collarLaceClassName)
            for (let item of classNames) {
              item.style.stroke = state.collarLaceColor
            }
          }
          break
        case 'body_color':
          let classNameList = state.bodyColorList[state.bodyColorSelectedIndex].classNameList
          let color = state.bodyColorList[state.bodyColorSelectedIndex].color
          if (classNameList) {
            for (let classItem of classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              for (let item of text) {
                item.style.fill = color
              }
            }
          }
          break
        case 'number': {
          if (state.numberLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13] && state.numberColorNumber in [0,1,2,3]) {
            let classNameList: any = state.numberLocationList[state.numberLocationSelectedIndex].classNameList
            for (let classItem of classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              let text1: any = document.getElementsByClassName(classItem + '_1')
              let text2: any = document.getElementsByClassName(classItem + '_2')
              // 设置颜色
              for (let item of text) {
                item.innerHTML = state.numberValue
                item.style.fill = state.numberColor[0]
              }
              if (state.numberColorNumber > 1) {
                for (let item of text1) {
                  item.style.stroke = state.numberColor[1]
                }
              }
              if (state.numberColorNumber > 2) {
                for (let item of text2) {
                  item.style.stroke = state.numberColor[2]
                }
              }
            }
          }
          break }
        case 'player_name': {
          if (state.playerNameColorNumber in [0,1,2,3]) {
            let classNameList: any = state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList
            for (let classItem of classNameList) {
              let text: any = document.getElementsByClassName(classItem)
              let text1: any = document.getElementsByClassName(classItem + '_1')
              let text2: any = document.getElementsByClassName(classItem + '_2')
              // 设置颜色
              for (let item of text) {
                item.innerHTML = state.playerNameValue
                item.style.fill = state.playerNameColor[0]
              }
              if (state.playerNameColorNumber > 1) {
                for (let item of text1) {
                  item.style.stroke = state.playerNameColor[1]
                }
              }
              if (state.playerNameColorNumber > 2) {
                for (let item of text2) {
                  item.style.stroke = state.playerNameColor[2]
                }
              }
            }
          }
          break }
        case 'team_name': {
          if (state.teamNameSelecteArea) {
            for (let classItem of state.teamNameSelecteArea) {
              let text: any = document.getElementsByClassName(classItem)
              let text1: any = document.getElementsByClassName(classItem + '_1')
              let text2: any = document.getElementsByClassName(classItem + '_2')
              // 设置颜色
              for (let item of text) {
                item.innerHTML = state.teamNameValue
                item.style.fill = state.teamNameNameColor[0]
              }
              if (state.teamNameColorNumber > 1) {
                for (let item of text1) {
                  item.style.stroke = state.teamNameNameColor[1]
                }
              }
              if (state.teamNameColorNumber > 2) {
                for (let item of text2) {
                  item.style.stroke = state.teamNameNameColor[2]
                }
              }
            }
          }
          break }
        case 'sponsor_name': {
          if (state.sponsorNameLocationSelectedIndex in [0,1,2,3] && state.sponsorNameColorNumber in [0,1,2,3]) {
            let className: any = state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className
            let text: any = document.getElementsByClassName(className)
            let text1: any = document.getElementsByClassName(className + '_1')
            let text2: any = document.getElementsByClassName(className + '_2')
            // 设置颜色
            for (let item of text) {
              item.innerHTML = state.sponsorNameValue
              item.style.fill = state.sponsorNameColor[0]
            }
            if (state.sponsorNameColorNumber > 1) {
              for (let item of text1) {
                item.style.stroke = state.sponsorNameColor[1]
              }
            }
            if (state.sponsorNameColorNumber > 2) {
              for (let item of text2) {
                item.style.stroke = state.sponsorNameColor[2]
              }
            }
          }
          break }
        case 'c_a': {
          if (state.caLocationSelectedIndex in [0,1,2] && state.caColorNumber in [0,1,2]) {
            let className: any = state.caLocationList[state.caLocationSelectedIndex].className
            let text: any = document.getElementsByClassName(className)
            let text1: any = document.getElementsByClassName(className + '_1')
            let text2: any = document.getElementsByClassName(className + '_2')
            // 设置颜色
            for (let item of text) {
              item.innerHTML = state.caValue
              item.style.fill = state.caColor[0]
            }
            if (state.caColorNumber > 1) {
              for (let item of text1) {
                item.style.stroke = state.caColor[1]
              }
            }
            if (state.caColorNumber > 2) {
              for (let item of text2) {
                item.style.stroke = state.caColor[2]
              }
            }
          }
          break }
      }

    }

    //获取总体区域
    const getzlistid = (data) => {
      gettotalarealistid(data).then((res: any) => {
        if (data.type == 0) {
          // Player Name
          state.playerNameDataList = res.rows
          for (let item of state.playerNameDataList) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName
            }
            state.playerNameLocationList.push(info)

            getzlist3({areaId: item.areaId}, 'player_name')
          }
          for (let item of state.playerNameDataList[state.playerNameLocationSelectedIndex].sizeList) {
            let sizeInfo = {
              label: item.dictLabel,
              value: item.dictValue,
            }
            state.playerNameSizeList.push(sizeInfo)
          }

        } else if (data.type == 1) {
          // sponsor logo
          state.sponsorLogoDataList = res.rows
          for (let item of state.sponsorLogoDataList) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName
            }
            state.sponsorLogoLocationList.push(info)

            getzlist3({areaId: item.areaId}, 'sponsor_logo')
          }
        } else if (data.type == 2) {
          // number
          state.numberDataList = res.rows
          for (let item of state.numberDataList) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName
            }
            state.numberLocationList.push(info)

            getzlist3({areaId: item.areaId}, 'number')
          }

        } else if (data.type == 3) {
          // team logo
          state.teamLogoDataList = res.rows
          for (let item of state.teamLogoDataList) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName
            }
            state.teamLogoLocationList.push(info)

            getzlist3({areaId: item.areaId}, 'team_logo')
          }

        } else if (data.type == 4) {
          // 衣领
          state.collarTypeList = res.rows
          if (state.collarTypeList.length > 0) {
            state.collarSelecteArea = res.rows[0].areaId
            state.selectcollname = res.rows[0].areaName
            for (let i in state.collarTypeList) {
              state.ztareaId = {
                areaId: state.collarTypeList[i].areaId,
              }
              getzlist4(state.ztareaId, i)
            }
          }
        } else if (data.type == 5) {
          state.Initialblockarea = res.rows
          if (state.Initialblockarea.length > 0) {
            let bodyMainColorAreaId = 0
            for (let i in state.Initialblockarea) {
              if (state.Initialblockarea[i].sort === 1) {
                bodyMainColorAreaId = state.Initialblockarea[i].areaId
                state.bodyMainColorIndex = parseInt(i)
              }
              state.ztareaId = {
                areaId: state.Initialblockarea[i].areaId,
              }
              getzlist5(state.ztareaId, bodyMainColorAreaId)
            }
          }
        } else if (data.type == 6) {
          state.cornerBottomList = []
          for (let item of res.rows) {
            let info = {
              label: item.areaName,
              value: item.className
            }
            state.cornerBottomList.push(info)
          }
          state.cornerBottomLable = state.cornerBottomList[state.cornerBottomSelectedIndex].lable
        } else if (data.type == 8) {
          // C A
          let data: any = res.rows
          for (let item of data) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName,
              className: item.className,
              sizeInch: "3''"
            }
            state.caLocationList.push(info)
          }
        } else if (data.type == 9) {
          state.teamNameAreaList = res.rows
          for (let i in state.teamNameAreaList) {
            state.teamNameAreaList[i].className =
              state.teamNameAreaList[i].className.split()
          }
        } else if (data.type == 10) {
          // Sponsor Name
          state.sponsorNameDataList = res.rows
          for (let item of state.sponsorNameDataList) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName,
              className: item.className
            }
            state.sponsorNameLocationList.push(info)
          }
        } else if (data.type == 11) {
          // Canada Flag
          let data: any = res.rows
          for (let item of data) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName,
              className: item.className,
              sizeInch: item.sizeList[0].dictLabel
            }
            state.canadaFlagLocationList.push(info)
          }
        } else if (data.type == 12) {
          // USA Flag
          let data: any = res.rows
          for (let item of data) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName,
              className: item.className,
              sizeInch: item.sizeList[0].dictLabel
            }
            state.usaFlagLocationList.push(info)
          }
        } else if (data.type == 13) {
          // Stop Sign
          let data: any = res.rows
          for (let item of data) {
            let info = {
              areaId: item.areaId,
              areaName: item.areaName,
              className: item.className,
              sizeInch: item.sizeList[item.sizeList.length - 1].dictLabel
            }
            state.stopSignLocationList.push(info)
          }
        }
      })
    }

    //获取区域列表 Body初始
    const getzlist5 = (data, bodyMainColorAreaId) => {
      gettotalarealist(data).then((res: any) => {
        setTimeout(async () => {
          let svgClassNameList = res.rows[0].svgClassNameList[0]
          let bodyClass: any = document.getElementsByClassName(svgClassNameList[0])
          // 获取SVG里颜色
          let colorHex = ''
          let colorName = ''
          for (let item of bodyClass) {
            colorHex = window
                .getComputedStyle(item, null)
                .getPropertyValue('fill')
            colorHex = colorRbgToHex(colorHex)
          }
          // 根据颜色获取颜色名称
          for (let item of state.colorList) {
            if (item.colorHexadecimal == colorHex) {
              colorName = item.colorName
            }
          }
          let bodyInfo = {
            classNameList: svgClassNameList,
            color: colorHex,
            colorName: colorName,
            isMain: res.rows[0].sort == 1
          }
          state.bodyColorList.push(bodyInfo)
          // 判断body主颜色
          if (data.areaId === bodyMainColorAreaId) {
            state.bodyMainColor = colorHex
          }
          initBodyMainColor()
        }, 1000)
      })
    }

    // 初始化body主颜色相关的默认颜色
    const initBodyMainColor = () => {
      state.bodyMainContraryColor = getColorHexContrary(state.bodyMainColor)
      state.bodyMainContraryColorName = getColorHexContraryName(state.bodyMainContraryColor)
      state.numberColor[0] = state.bodyMainContraryColor
      state.numberColor[1] = state.bodyMainContraryColor
      state.numberColor[2] = state.bodyMainContraryColor
      state.numberColorName[0] = state.bodyMainContraryColorName
      state.numberColorName[1] = state.bodyMainContraryColorName
      state.numberColorName[2] = state.bodyMainContraryColorName

      state.playerNameColor[0] = state.bodyMainContraryColor
      state.playerNameColor[1] = state.bodyMainContraryColor
      state.playerNameColor[2] = state.bodyMainContraryColor
      state.playerNameColorName[0] = state.bodyMainContraryColorName
      state.playerNameColorName[1] = state.bodyMainContraryColorName
      state.playerNameColorName[2] = state.bodyMainContraryColorName

      state.teamNameNameColor[0] = state.bodyMainContraryColor
      state.teamNameNameColor[1] = state.bodyMainContraryColor
      state.teamNameNameColor[2] = state.bodyMainContraryColor
      state.teamNameNameColorName[0] = state.bodyMainContraryColorName
      state.teamNameNameColorName[1] = state.bodyMainContraryColorName
      state.teamNameNameColorName[2] = state.bodyMainContraryColorName

      state.sponsorNameColor[0] = state.bodyMainContraryColor
      state.sponsorNameColor[1] = state.bodyMainContraryColor
      state.sponsorNameColor[2] = state.bodyMainContraryColor
      state.sponsorNameColorName[0] = state.bodyMainContraryColorName
      state.sponsorNameColorName[1] = state.bodyMainContraryColorName
      state.sponsorNameColorName[2] = state.bodyMainContraryColorName

      state.caColor[0] = state.bodyMainContraryColor
      state.caColor[1] = state.bodyMainContraryColor
      state.caColor[2] = state.bodyMainContraryColor
      state.caColorName[0] = state.bodyMainContraryColorName
      state.caColorName[1] = state.bodyMainContraryColorName
      state.caColorName[2] = state.bodyMainContraryColorName
    }

    //获取区域列表
    const getzlist4 = (data, index: any) => {
      state.collarAreaData = new Object()
      gettotalarealist(data).then((res: any) => {
        for (let item of state.collarTypeList) {
          if (item.areaId == data.areaId) {
            state.collarAreaData[item.className] = res.rows
          }
        }
        if (state.collarTypeList.length == parseInt(index) + 1) {
          getSvgCollarTypeSelectedIndex()
        }
      })
    }

    const rgbToHex = (color) => {
      var values = color
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',')
      var a = parseFloat(values[3] || 1),
        r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)
      return (
        '#' +
        ('0' + r.toString(16)).slice(-2) +
        ('0' + g.toString(16)).slice(-2) +
        ('0' + b.toString(16)).slice(-2)
      )
    }
    //获取区域列表
    const getzlist3 = (data, type) => {
      gettotalarealist(data).then((res: any) => {
        let data = res.rows[0]
        switch (type) {
          case 'team_logo':
            for (let item of state.teamLogoLocationList) {
              if (item.areaId == data.areaId) {
                item['classNameList'] = data.svgClassNameList[0]
              }
            }
            break
          case 'number':
            for (let item of state.numberLocationList) {
              if (item.areaId == data.areaId) {
                item['classNameList'] = data.svgClassNameList[0]
              }
            }
            break
          case 'player_name':
            for (let item of state.playerNameLocationList) {
              if (item.areaId == data.areaId) {
                item['classNameList'] = data.svgClassNameList[0]
              }
            }
            break
          case 'sponsor_logo':
            for (let item of state.sponsorLogoLocationList) {
              if (item.areaId == data.areaId) {
                item['classNameList'] = data.svgClassNameList[0]
              }
            }
            break
        }
      })
    }

    // 调接口获取颜色数据
    const getColorListData = () => {
        getColorList().then((res: any) => {
          state.colorList = res.rows
        })
    }

    // 获取字体列表
    const getTextFontData = () => {
      getTextFont().then((res) => {
        state.textFontList = res.data
        state.numberFamily = state.textFontList[0].dictValue
        state.playerNameFamily = state.textFontList[0].dictValue
        state.teamNameFamily = state.textFontList[0].dictValue
        state.sponsorNameFamily = state.textFontList[0].dictValue
        state.caFamily = state.textFontList[0].dictValue
      })
    }
    // 下单
    const placeanOrder = (v) => {
      if (state.show === 'team_logo' ||
          state.show === 'number' ||
          state.show === 'player_name' ||
          state.show === 'team_name' ||
          state.show === 'sponsor_name' ||
          state.show === 'sponsor_logo' ||
          state.show === 'flags_stop_signs' ||
          state.show === 'c_a') {
        ElMessage({
          message: 'Please save decorations.',
          type: 'warning',
        })
        return
      }
      state.dialogVisible = v
    }
    // exitDialog
    const exitDialog = (v) => {
      state.exitDialogVisible = v
    }
    // exit 退出，返回至上一页
    const exit = (v) => {
      window.location.replace("https://kustomizer.kobesportswear.com")
    }

    // 刷新时清除缓存
    window.onbeforeunload = (e) => {
      // sessionStorage.clear()
    }

    onMounted(() => {
      // sessionStorage.clear()
      getGoodsInfo(route.query.id).then((res) => {
        state.goodsName = res.data.name
        apiGetUserInfoz(res.data.svgFUrl).then((res: any) => {
          state.svg = res
          getClassListData()
        })
      })


    })

    const getClassListData = () => {
      getColorListData()

      let data6 = {
        goodsDetailsId: route.query.id,
        type: 5,
      }

      getzlistid(data6)
      let data7 = {
        goodsDetailsId: route.query.id,
        type: 6,
      }
      getzlistid(data7)

      let data = {
        goodsDetailsId: route.query.id,
        type: 1,
      }
      getzlistid(data)
      let data2 = {
        goodsDetailsId: route.query.id,
        type: 0,
      }
      getzlistid(data2)
      let data3 = {
        goodsDetailsId: route.query.id,
        type: 2,
      }
      getzlistid(data3)
      let data4 = {
        goodsDetailsId: route.query.id,
        type: 3,
      }
      getzlistid(data4)
      let data8 = {
        goodsDetailsId: route.query.id,
        type: 8,
      }
      getzlistid(data8)

      let data9 = {
        goodsDetailsId: route.query.id,
        type: 9,
      }
      getzlistid(data9)

      let data10 = {
        goodsDetailsId: route.query.id,
        type: 10,
      }
      getzlistid(data10)
      let data11 = {
        goodsDetailsId: route.query.id,
        type: 11,
      }
      getzlistid(data11)

      let data12 = {
        goodsDetailsId: route.query.id,
        type: 12,
      }
      getzlistid(data12)

      let data13 = {
        goodsDetailsId: route.query.id,
        type: 13,
      }
      getzlistid(data13)

      if (state.havaCollar === 'true') {
        let data5 = {
          goodsDetailsId: route.query.id,
          type: 4,
        }
        getzlistid(data5)
      }
      // 文字样式列表
      getTextFontData()
    }

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          // 判断邮箱是否一致
          if (state.ruleForm.email != state.ruleForm.reemail) {
            ElMessage({
              message: 'The email entered twice is inconsistent!',
              type: 'warning',
            })
            return
          }
          state.loading = true

          // 判断衣服尺码数量
          let isSizeNumber = false
          for (let i in state.atSizeList.youth){
            if (state.atSizeList.youth[i] > 0) {
              isSizeNumber = true
            } else {
              state.atSizeList.youth[i] = 0
            }
          }
          for (let i in state.atSizeList.adult){
            if (state.atSizeList.adult[i] > 0) {
              isSizeNumber = true
            } else {
              state.atSizeList.adult[i] = 0
            }
          }
          if (!isSizeNumber) {
            state.loading = false
            ElMessage({
              message: 'Please enter the quantity!',
              type: 'warning',
            })
            return
          }
          state.dialogVisible = false
          // 获取订单编号
          const ordeerNumberRes = await getOrderNumber()
          // 订单信息
          state.orderinfo.goodsDetailsId = route.query.id
          state.orderinfo.clientName = state.ruleForm.name
          state.orderinfo.clientPhone = state.ruleForm.phone
          state.orderinfo.clientEmail = state.ruleForm.email
          state.orderinfo.clientAddress = state.ruleForm.address
          state.orderinfo.orderNumber = ordeerNumberRes.data.orderNumber

          // 布料
          state.fabric = state.goodsName.slice(0, 3)
          // 创建订单的时间
          const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
          const month = months[parseInt(state.orderinfo.orderNumber.slice(4, 6)) - 1];
          const day = state.orderinfo.orderNumber.slice(6, 8);
          const year = state.orderinfo.orderNumber.slice(0, 4);
          state.createOrderTimes = `${month} ${day}, ${year}`

          // 上传svg获取png文件
          let img1 = svgchangepng('SVG_1')
          let pngData: any = await svgup(img1)
          state.pngDataUrl = pngData.url
          // 往第一页html代码里添加衣服png
          let pngMockup: any = document.getElementById('png_mockup')
          pngMockup.src = state.pngDataUrl

          let pngCollarMockupDetails: any = document.getElementById('png_collar_mockup_details')
          pngCollarMockupDetails.src = state.pngDataUrl

          let pngBottomFeaturesMockupDetails: any = document.getElementById('png_bottom_features_mockup_details')
          pngBottomFeaturesMockupDetails.src = state.pngDataUrl

          // 往第一页html代码里添加衣服颜色块
          state.colorMockup = document.getElementById('color_mockup')
          // 色块个数
          for (let item of state.bodyColorList) {
            // 创建色块
            const child = document.createElement('span');
            // 色块样式
            let spanStyle = {
              border: '#999 2px solid',
              'background-color': item.color,
              'border-radius': '5px',
              width: '140px',
              height: '30px',
              display: 'block',
              'text-align': 'center',
              color: getColorHexContrary(item.color),
              'line-height': '30px',
              margin: '10px 5px 10px 0px',
              'font-size': '12px',
            }
            // 色块添加样式
            for (let i in spanStyle){
              child.style[i] = spanStyle[i]
            }
            // 色块颜色名称
            child.innerHTML = item.colorName
            // 添加到html代码
            state.colorMockup.appendChild(child)
          }
          // 上传第一页html代码获取url
          let onePageFileBase = htmlToFileById('page1')
          let onePageUrlData: any = await htmlup(onePageFileBase)
          state.orderPdfUrl[0] = onePageUrlData.url

          state.pageTowPageNumber = 0
          // 如果更换过衣领
          if (state.collarTypeSelectedInitialIndex !== state.collarTypeSelectedIndex) {
            state.pageTowPageNumber++
          }
          // 如果更换过下摆
          if (state.cornerBottomSelectedIndex === 1 || state.jerseyTieDownShow) {
            state.pageTowPageNumber++
          }

          // 总页数 计算需要几张第二页(向上取整)
          state.totalPageNumber = Math.ceil((state.operationDataList.length + state.pageTowPageNumber) / 4)
          // 当前页数
          state.currentPageNumber = 1
          // 当前表格总条数
          state.currentTotalTableNumber = 0
          // 当前页的表格条数
          state.currentTableNumber = 0

          // 背景设置为Body主颜色
          let tabBgClass: any = document.getElementsByClassName('tab_bg_page2')
          for (let item of tabBgClass) {
            item.style.backgroundColor = state.bodyColorList[state.bodyMainColorIndex].color
          }

          // 获取详情表格的容器
          state.mockupDetails = document.getElementById('mockup_details')
          // 获取衣领的表格
          state.collarMockupDetails = document.getElementById('collar_mockup_details')
          state.collarMockupDetailsNeckType = document.getElementById('collar_mockup_details_neck_type')
          state.collarMockupDetailsNeckTypeColor = document.getElementById('collar_mockup_details_neck_type_color')
          state.collarMockupDetailsUnderlayColor = document.getElementById('collar_mockup_details_underlay_color')
          state.collarMockupDetailsLaceColor = document.getElementById('collar_mockup_details_lace_color')
          // 获取衣服底部特征
          state.bottomFeaturesMockupDetails = document.getElementById('bottom_features_mockup_details')
          state.bottomFeaturesMockupDetailsBottom = document.getElementById('bottom_features_mockup_details_bottom')
          state.bottomFeaturesMockupDetailsSplit = document.getElementById('bottom_features_mockup_details_split')
          state.bottomFeaturesMockupDetailsJerseyTieDown = document.getElementById('bottom_features_mockup_details_jersey_tie_down')

          // 获取文字记录的表格
          state.textMockupDetails1 = document.getElementById('text_mockup_details_1')
          state.textMockupDetails2 = document.getElementById('text_mockup_details_2')
          state.textMockupDetails3 = document.getElementById('text_mockup_details_3')
          state.textMockupDetails4 = document.getElementById('text_mockup_details_4')
          // 文字记录的title
          state.textMockupDetailsTitle1 = document.getElementById('text_mockup_details_title_1')
          state.textMockupDetailsTitle2 = document.getElementById('text_mockup_details_title_2')
          state.textMockupDetailsTitle3 = document.getElementById('text_mockup_details_title_3')
          state.textMockupDetailsTitle4 = document.getElementById('text_mockup_details_title_4')
          // 文字记录的value
          state.textMockupDetailsValue1 = document.getElementById('text_mockup_details_value_1')
          state.textMockupDetailsValue2 = document.getElementById('text_mockup_details_value_2')
          state.textMockupDetailsValue3 = document.getElementById('text_mockup_details_value_3')
          state.textMockupDetailsValue4 = document.getElementById('text_mockup_details_value_4')
          // 文字记录的font
          state.textMockupDetailsFont1 = document.getElementById('text_mockup_font_1')
          state.textMockupDetailsFont2 = document.getElementById('text_mockup_font_2')
          state.textMockupDetailsFont3 = document.getElementById('text_mockup_font_3')
          state.textMockupDetailsFont4 = document.getElementById('text_mockup_font_4')
          // 文字记录的application
          state.textMockupDetailsApplication1 = document.getElementById('text_mockup_application_1')
          state.textMockupDetailsApplication2 = document.getElementById('text_mockup_application_2')
          state.textMockupDetailsApplication3 = document.getElementById('text_mockup_application_3')
          state.textMockupDetailsApplication4 = document.getElementById('text_mockup_application_4')
          // 文字记录的location
          state.textMockupDetailsLocation1 = document.getElementById('text_mockup_location_1')
          state.textMockupDetailsLocation2 = document.getElementById('text_mockup_location_2')
          state.textMockupDetailsLocation3 = document.getElementById('text_mockup_location_3')
          state.textMockupDetailsLocation4 = document.getElementById('text_mockup_location_4')
          // 文字记录的size
          state.textMockupDetailsSize1 = document.getElementById('text_mockup_size_1')
          state.textMockupDetailsSize2 = document.getElementById('text_mockup_size_2')
          state.textMockupDetailsSize3 = document.getElementById('text_mockup_size_3')
          state.textMockupDetailsSize4 = document.getElementById('text_mockup_size_4')
          // 文字记录的color
          state.textMockupDetailsColor1 = document.getElementById('text_mockup_color_1')
          state.textMockupDetailsColor2 = document.getElementById('text_mockup_color_2')
          state.textMockupDetailsColor3 = document.getElementById('text_mockup_color_3')
          state.textMockupDetailsColor4 = document.getElementById('text_mockup_color_4')

          // 获取文字记录的表格
          state.textMockupDetailsNoSize1 = document.getElementById('text_mockup_details_no_size_1')
          state.textMockupDetailsNoSize2 = document.getElementById('text_mockup_details_no_size_2')
          state.textMockupDetailsNoSize3 = document.getElementById('text_mockup_details_no_size_3')
          state.textMockupDetailsNoSize4 = document.getElementById('text_mockup_details_no_size_4')
          // 文字记录的title
          state.textMockupDetailsTitleNoSize1 = document.getElementById('text_mockup_details_title_no_size_1')
          state.textMockupDetailsTitleNoSize2 = document.getElementById('text_mockup_details_title_no_size_2')
          state.textMockupDetailsTitleNoSize3 = document.getElementById('text_mockup_details_title_no_size_3')
          state.textMockupDetailsTitleNoSize4 = document.getElementById('text_mockup_details_title_no_size_4')
          // 文字记录的value
          state.textMockupDetailsValueNoSize1 = document.getElementById('text_mockup_details_value_no_size_1')
          state.textMockupDetailsValueNoSize2 = document.getElementById('text_mockup_details_value_no_size_2')
          state.textMockupDetailsValueNoSize3 = document.getElementById('text_mockup_details_value_no_size_3')
          state.textMockupDetailsValueNoSize4 = document.getElementById('text_mockup_details_value_no_size_4')
          // 文字记录的font
          state.textMockupDetailsFontNoSize1 = document.getElementById('text_mockup_font_no_size_1')
          state.textMockupDetailsFontNoSize2 = document.getElementById('text_mockup_font_no_size_2')
          state.textMockupDetailsFontNoSize3 = document.getElementById('text_mockup_font_no_size_3')
          state.textMockupDetailsFontNoSize4 = document.getElementById('text_mockup_font_no_size_4')
          // 文字记录的application
          state.textMockupDetailsApplicationNoSize1 = document.getElementById('text_mockup_application_no_size_1')
          state.textMockupDetailsApplicationNoSize2 = document.getElementById('text_mockup_application_no_size_2')
          state.textMockupDetailsApplicationNoSize3 = document.getElementById('text_mockup_application_no_size_3')
          state.textMockupDetailsApplicationNoSize4 = document.getElementById('text_mockup_application_no_size_4')
          // 文字记录的location
          state.textMockupDetailsLocationNoSize1 = document.getElementById('text_mockup_location_no_size_1')
          state.textMockupDetailsLocationNoSize2 = document.getElementById('text_mockup_location_no_size_2')
          state.textMockupDetailsLocationNoSize3 = document.getElementById('text_mockup_location_no_size_3')
          state.textMockupDetailsLocationNoSize4 = document.getElementById('text_mockup_location_no_size_4')
          // 文字记录的color
          state.textMockupDetailsColorNoSize1 = document.getElementById('text_mockup_color_no_size_1')
          state.textMockupDetailsColorNoSize2 = document.getElementById('text_mockup_color_no_size_2')
          state.textMockupDetailsColorNoSize3 = document.getElementById('text_mockup_color_no_size_3')
          state.textMockupDetailsColorNoSize4 = document.getElementById('text_mockup_color_no_size_4')

          // 获取图片记录的表格
          state.imgMockupDetails1 = document.getElementById('img_mockup_details_1')
          state.imgMockupDetails2 = document.getElementById('img_mockup_details_2')
          state.imgMockupDetails3 = document.getElementById('img_mockup_details_3')
          state.imgMockupDetails4 = document.getElementById('img_mockup_details_4')
          // 图片记录的title
          state.imgMockupDetailsTitle1 = document.getElementById('img_mockup_details_title_1')
          state.imgMockupDetailsTitle2 = document.getElementById('img_mockup_details_title_2')
          state.imgMockupDetailsTitle3 = document.getElementById('img_mockup_details_title_3')
          state.imgMockupDetailsTitle4 = document.getElementById('img_mockup_details_title_4')
          // 图片记录的URL
          state.imgMockupDetailsUrl1 = document.getElementById('img_mockup_details_url_1')
          state.imgMockupDetailsUrl2 = document.getElementById('img_mockup_details_url_2')
          state.imgMockupDetailsUrl3 = document.getElementById('img_mockup_details_url_3')
          state.imgMockupDetailsUrl4 = document.getElementById('img_mockup_details_url_4')
          // 图片记录的application
          state.imgMockupDetailsApplication1 = document.getElementById('img_mockup_details_application_1')
          state.imgMockupDetailsApplication2 = document.getElementById('img_mockup_details_application_2')
          state.imgMockupDetailsApplication3 = document.getElementById('img_mockup_details_application_3')
          state.imgMockupDetailsApplication4 = document.getElementById('img_mockup_details_application_4')
          // 图片记录的location
          state.imgMockupDetailsLocation1 = document.getElementById('img_mockup_details_location_1')
          state.imgMockupDetailsLocation2 = document.getElementById('img_mockup_details_location_2')
          state.imgMockupDetailsLocation3 = document.getElementById('img_mockup_details_location_3')
          state.imgMockupDetailsLocation4 = document.getElementById('img_mockup_details_location_4')
          // 图片记录的size
          state.imgMockupDetailsSize1 = document.getElementById('img_mockup_details_size_1')
          state.imgMockupDetailsSize2 = document.getElementById('img_mockup_details_size_2')
          state.imgMockupDetailsSize3 = document.getElementById('img_mockup_details_size_3')
          state.imgMockupDetailsSize4 = document.getElementById('img_mockup_details_size_4')

          // 获取图片记录的表格
          state.imgMockupDetailsNoSize1 = document.getElementById('img_mockup_details_no_size_1')
          state.imgMockupDetailsNoSize2 = document.getElementById('img_mockup_details_no_size_2')
          state.imgMockupDetailsNoSize3 = document.getElementById('img_mockup_details_no_size_3')
          state.imgMockupDetailsNoSize4 = document.getElementById('img_mockup_details_no_size_4')
          // 图片记录的title
          state.imgMockupDetailsTitleNoSize1 = document.getElementById('img_mockup_details_title_no_size_1')
          state.imgMockupDetailsTitleNoSize2 = document.getElementById('img_mockup_details_title_no_size_2')
          state.imgMockupDetailsTitleNoSize3 = document.getElementById('img_mockup_details_title_no_size_3')
          state.imgMockupDetailsTitleNoSize4 = document.getElementById('img_mockup_details_title_no_size_4')
          // 图片记录的URL
          state.imgMockupDetailsUrlNoSize1 = document.getElementById('img_mockup_details_url_no_size_1')
          state.imgMockupDetailsUrlNoSize2 = document.getElementById('img_mockup_details_url_no_size_2')
          state.imgMockupDetailsUrlNoSize3 = document.getElementById('img_mockup_details_url_no_size_3')
          state.imgMockupDetailsUrlNoSize4 = document.getElementById('img_mockup_details_url_no_size_4')
          // 图片记录的application
          state.imgMockupDetailsApplicationNoSize1 = document.getElementById('img_mockup_details_application_no_size_1')
          state.imgMockupDetailsApplicationNoSize2 = document.getElementById('img_mockup_details_application_no_size_2')
          state.imgMockupDetailsApplicationNoSize3 = document.getElementById('img_mockup_details_application_no_size_3')
          state.imgMockupDetailsApplicationNoSize4 = document.getElementById('img_mockup_details_application_no_size_4')
          // 图片记录的location
          state.imgMockupDetailsLocationNoSize1 = document.getElementById('img_mockup_details_location_no_size_1')
          state.imgMockupDetailsLocationNoSize2 = document.getElementById('img_mockup_details_location_no_size_2')
          state.imgMockupDetailsLocationNoSize3 = document.getElementById('img_mockup_details_location_no_size_3')
          state.imgMockupDetailsLocationNoSize4 = document.getElementById('img_mockup_details_location_no_size_4')

          // 各类表格数据
          let teamLogoList = new Array(0);
          let numberList = new Array(0);
          let playerNameList = new Array(0);
          let teamNameList = new Array(0);
          let sponsorNameList = new Array(0);
          let sponsorLogoList = new Array(0);
          let flagSignLogoList = new Array(0);
          let caList = new Array(0);

          for (let item of state.operationDataList) {
            switch (item.type) {
              case 'team_logo':
                teamLogoList.push(item)
                break
              case 'number':
                numberList.push(item)
                break
              case 'player_name':
                playerNameList.push(item)
                break
              case 'team_name':
                teamNameList.push(item)
                break
              case 'sponsor_name':
                sponsorNameList.push(item)
                break
              case 'sponsor_logo':
                sponsorLogoList.push(item)
                break
              case 'canada_flag':
              case 'usa_flag':
              case 'stop_sign':
                flagSignLogoList.push(item)
                break
              case 'c_a':
                caList.push(item)
                break
            }
          }
          console.log('===state.operationDataList===', state.operationDataList)
          // 如果没有第二页，提交订单
          if (state.totalPageNumber === 0) {
            console.log("==提交订单==currentPageNumber:0")
            insertCustomerOrder()
            return
          }
          // 如果更换过衣领
          if (state.collarTypeSelectedInitialIndex !== state.collarTypeSelectedIndex) {
            htmlAddCollar()
          }
          // 如果更换过下摆
          if (state.cornerBottomSelectedIndex === 1 || state.jerseyTieDownShow) {
            htmlBottomFeatures()
          }
          htmlAddImg(teamLogoList)
          htmlAddText(numberList)
          htmlAddTextNoSize(playerNameList)
          htmlAddTextNoSize(teamNameList)
          htmlAddTextNoSize(sponsorNameList)
          htmlAddImg(sponsorLogoList)
          htmlAddImgNoSize(flagSignLogoList)
          htmlAddTextNoSize(caList)

        } else {
          ElMessage.error('error submit!')
        }
      })
    }

    // 在HTML里添加Collar
    const htmlAddCollar = () => {
      // 衣领类型
      console.log('===在HTML里添加Collar===', state.collarMockupDetailsNeckType)
      if (state.collarTypeSelectedIndex === '') {
        state.collarMockupDetailsNeckType.innerHTML = 'DEFAULT'
      } else {
        state.collarMockupDetailsNeckType.innerHTML = state.collarTypeList[state.collarTypeSelectedIndex].areaName
      }
      let textMockupDetailsColorSpanStyle = {
        float: 'left',
        margin: '0px 20px 0px 5px',
      }
      // 衣领颜色
      for (let collarIndex = 0; collarIndex < state.collarColorNumber; collarIndex++) {
        let textMockupDetailsColorDivStyle = getTextMockupDetailsColorDivStyle(state.collarColorList[collarIndex])
        // 创建文字颜色DIV1
        const textColorDiv = document.createElement('div');
        // 文字颜色DIV1添加样式
        for (let i in textMockupDetailsColorDivStyle){
          textColorDiv.style[i] = textMockupDetailsColorDivStyle[i]
        }
        // 创建文字颜色span1
        const textColorSpan = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan.innerHTML = state.collarColorNameList[collarIndex]
        state.collarMockupDetailsNeckTypeColor.appendChild(textColorDiv)
        state.collarMockupDetailsNeckTypeColor.appendChild(textColorSpan)
      }
      // 衣领底色
      if (state.collarUnderlayColor !== '') {
        let textMockupDetailsColorDivStyle = getTextMockupDetailsColorDivStyle(state.collarUnderlayColor)
        // 创建文字颜色DIV1
        const textColorDiv = document.createElement('div');
        // 文字颜色DIV1添加样式
        for (let i in textMockupDetailsColorDivStyle){
          textColorDiv.style[i] = textMockupDetailsColorDivStyle[i]
        }
        // 创建文字颜色span1
        const textColorSpan = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan.innerHTML = state.collarUnderlayColorName
        state.collarMockupDetailsUnderlayColor.appendChild(textColorDiv)
        state.collarMockupDetailsUnderlayColor.appendChild(textColorSpan)
      }
      // 衣领蕾丝
      if (state.collarLaceColor !== '') {
        let textMockupDetailsColorDivStyle = getTextMockupDetailsColorDivStyle(state.collarLaceColor)
        // 创建文字颜色DIV1
        const textColorDiv = document.createElement('div');
        // 文字颜色DIV1添加样式
        for (let i in textMockupDetailsColorDivStyle){
          textColorDiv.style[i] = textMockupDetailsColorDivStyle[i]
        }
        // 创建文字颜色span1
        const textColorSpan = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan.innerHTML = state.collarLaceColorName
        state.collarMockupDetailsLaceColor.appendChild(textColorDiv)
        state.collarMockupDetailsLaceColor.appendChild(textColorSpan)
      }
      // 添加到表格容器
      state.mockupDetails.appendChild(state.collarMockupDetails)
      // 表格条数加1
      state.currentTableNumber++
      state.currentTotalTableNumber++
      if (state.currentTotalTableNumber == state.operationDataList.length + state.pageTowPageNumber) {
        // 上传详情页
        uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
      }
    }

    // 在HTML里添加衣服底部特征
    const htmlBottomFeatures = () => {
      state.bottomFeaturesMockupDetailsBottom.innerHTML = state.cornerBottomList[state.cornerBottomSelectedIndex].label
      if (state.cornerBottomSplitValue !== '') {
        state.bottomFeaturesMockupDetailsSplit.innerHTML = state.cornerBottomSplitValue
      }
      state.bottomFeaturesMockupDetailsJerseyTieDown.innerHTML = state.jerseyTieDownShow ? 'Yes' : 'No'
      // 添加到表格容器
      state.mockupDetails.appendChild(state.bottomFeaturesMockupDetails)
      // 表格条数加1
      state.currentTableNumber++
      state.currentTotalTableNumber++
      if (state.currentTotalTableNumber == state.operationDataList.length + state.pageTowPageNumber) {
        // 上传详情页
        uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
      }
    }

    // 在HTML里添加Img
    const htmlAddImg = (logoList) => {
      for (let item of logoList) {
        // 图片span样式
        let imgMockupDetailsUrlStyle = {
          position: 'absolute',
          top: '15px',
          left: '347px',
          width: '130px',
          height: '130px',
          'background-size': '100% 100%',
          'background-image': getImgMockupDetailsUrl(item.content),
        }
        // 创建图片span
        const imgSpan = document.createElement('span');
        // 图片添加样式
        for (let i in imgMockupDetailsUrlStyle){
          imgSpan.style[i] = imgMockupDetailsUrlStyle[i]
        }

        if (state.currentTableNumber == 0) {
          // 设置标题
          state.imgMockupDetailsTitle1.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrl1.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplication1.innerHTML = item.application
          state.imgMockupDetailsLocation1.innerHTML = item.positio
          state.imgMockupDetailsSize1.innerHTML = item.sizeInch
          state.mockupDetails.appendChild(state.imgMockupDetails1)
        } else if (state.currentTableNumber == 1) {
          // 设置标题
          state.imgMockupDetailsTitle2.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrl2.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplication2.innerHTML = item.application
          state.imgMockupDetailsLocation2.innerHTML = item.positio
          state.imgMockupDetailsSize2.innerHTML = item.sizeInch
          state.mockupDetails.appendChild(state.imgMockupDetails2)
        } else if (state.currentTableNumber == 2) {
          // 设置标题
          state.imgMockupDetailsTitle3.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrl3.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplication3.innerHTML = item.application
          state.imgMockupDetailsLocation3.innerHTML = item.positio
          state.imgMockupDetailsSize3.innerHTML = item.sizeInch
          state.mockupDetails.appendChild(state.imgMockupDetails3)
        } else {
          // 设置标题
          state.imgMockupDetailsTitle4.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrl4.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplication4.innerHTML = item.application
          state.imgMockupDetailsLocation4.innerHTML = item.positio
          state.imgMockupDetailsSize4.innerHTML = item.sizeInch
          state.mockupDetails.appendChild(state.imgMockupDetails4)
        }
        state.currentTotalTableNumber++
        if (state.currentTableNumber == 3) {
          // 填完一页
          state.currentTableNumber = 0
          // 上传详情页
          uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          // 当前页数
          state.currentPageNumber++
        } else {
          state.currentTableNumber++
          if (state.currentTotalTableNumber == state.operationDataList.length + state.pageTowPageNumber) {
            // 上传详情页
            uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          }
        }
      }
    }

    // 在HTML里添加Img
    const htmlAddImgNoSize = (logoList) => {
      for (let item of logoList) {
        // 图片span样式
        let imgMockupDetailsUrlStyle = {
          position: 'absolute',
          top: '15px',
          left: '347px',
          width: '130px',
          height: '130px',
          'background-size': '100% 100%',
          'background-image': getImgMockupDetailsUrl(item.content),
        }
        // 创建图片span
        const imgSpan = document.createElement('span');
        // 图片添加样式
        for (let i in imgMockupDetailsUrlStyle){
          imgSpan.style[i] = imgMockupDetailsUrlStyle[i]
        }

        if (state.currentTableNumber == 0) {
          // 设置标题
          state.imgMockupDetailsTitleNoSize1.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrlNoSize1.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplicationNoSize1.innerHTML = item.application
          state.imgMockupDetailsLocationNoSize1.innerHTML = item.positio
          state.mockupDetails.appendChild(state.imgMockupDetailsNoSize1)
        } else if (state.currentTableNumber == 1) {
          // 设置标题
          state.imgMockupDetailsTitleNoSize2.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrlNoSize2.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplicationNoSize2.innerHTML = item.application
          state.imgMockupDetailsLocationNoSize2.innerHTML = item.positio
          state.mockupDetails.appendChild(state.imgMockupDetailsNoSize2)
        } else if (state.currentTableNumber == 2) {
          // 设置标题
          state.imgMockupDetailsTitleNoSize3.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrlNoSize3.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplicationNoSize3.innerHTML = item.application
          state.imgMockupDetailsLocationNoSize3.innerHTML = item.positio
          state.mockupDetails.appendChild(state.imgMockupDetailsNoSize3)
        } else {
          // 设置标题
          state.imgMockupDetailsTitleNoSize4.innerHTML = getTypeName(item.type)
          // 添加图片到html
          state.imgMockupDetailsUrlNoSize4.appendChild(imgSpan)
          // 设置其他值
          state.imgMockupDetailsApplicationNoSize4.innerHTML = item.application
          state.imgMockupDetailsLocationNoSize4.innerHTML = item.positio
          state.mockupDetails.appendChild(state.imgMockupDetailsNoSize4)
        }
        state.currentTotalTableNumber++
        if (state.currentTableNumber == 3) {
          // 填完一页
          state.currentTableNumber = 0
          // 上传详情页
          uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          // 当前页数
          state.currentPageNumber++
        } else {
          state.currentTableNumber++
          if (state.currentTotalTableNumber == state.operationDataList.length + state.pageTowPageNumber) {
            // 上传详情页
            uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          }
        }
      }
    }

    const htmlAddText = (textList) => {
      for (let item of textList) {
        let textValue = item.value === 'number' ? '1234567890' : item.value
        let textValueMockupDetailsSpanStyle1 = getTextValueMockupDetailsStyle(1, item.colorNumber, item.textColor1, item.font)
        let textValueMockupDetailsSpanStyle2 = getTextValueMockupDetailsStyle(2, item.colorNumber, item.textColor2, item.font)
        let textValueMockupDetailsSpanStyle3 = getTextValueMockupDetailsStyle(3, item.colorNumber, item.textColor3, item.font)
        // 创建文字span
        const textValueSpan1 = document.createElement('span');
        const textValueSpan2 = document.createElement('span');
        const textValueSpan3 = document.createElement('span');
        // 文字span添加样式
        for (let i in textValueMockupDetailsSpanStyle1){
          textValueSpan1.style[i] = textValueMockupDetailsSpanStyle1[i]
        }
        for (let i in textValueMockupDetailsSpanStyle2){
          textValueSpan2.style[i] = textValueMockupDetailsSpanStyle2[i]
        }
        for (let i in textValueMockupDetailsSpanStyle3){
          textValueSpan3.style[i] = textValueMockupDetailsSpanStyle3[i]
        }
        textValueSpan1.innerHTML = textValue
        textValueSpan2.innerHTML = textValue
        textValueSpan3.innerHTML = textValue

        // 文字div样式
        let textMockupDetailsColorDivStyle1 = getTextMockupDetailsColorDivStyle(item.textColor1)
        let textMockupDetailsColorDivStyle2 = getTextMockupDetailsColorDivStyle(item.textColor2)
        let textMockupDetailsColorDivStyle3 = getTextMockupDetailsColorDivStyle(item.textColor3)
        // 文字span样式
        let textMockupDetailsColorSpanStyle = {
          float: 'left',
          margin: '0px 20px 0px 5px',
        }
        // ------颜色1-----
        // 创建文字颜色DIV1
        const textColorDiv1 = document.createElement('div');
        // 文字颜色DIV1添加样式
        for (let i in textMockupDetailsColorDivStyle1){
          textColorDiv1.style[i] = textMockupDetailsColorDivStyle1[i]
        }
        // 创建文字颜色span1
        const textColorSpan1 = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan1.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan1.innerHTML = item.textColorName1
        // ------颜色2-----
        // 创建文字颜色DIV2
        const textColorDiv2 = document.createElement('div');
        // 文字颜色DIV2添加样式
        for (let i in textMockupDetailsColorDivStyle2){
          textColorDiv2.style[i] = textMockupDetailsColorDivStyle2[i]
        }
        // 创建文字颜色span2
        const textColorSpan2 = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan2.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan2.innerHTML = item.textColorName2
        // ------颜色3-----
        // 创建文字颜色DIV3
        const textColorDiv3 = document.createElement('div');
        // 文字颜色DIV3添加样式
        for (let i in textMockupDetailsColorDivStyle3){
          textColorDiv3.style[i] = textMockupDetailsColorDivStyle3[i]
        }
        // 创建文字颜色span3
        const textColorSpan3 = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan3.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan3.innerHTML = item.textColorName3

        if (state.currentTableNumber == 0) {
          // 设置标题
          state.textMockupDetailsTitle1.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValue1.appendChild(textValueSpan1)
          state.textMockupDetailsValue1.appendChild(textValueSpan2)
          state.textMockupDetailsValue1.appendChild(textValueSpan3)
          state.textMockupDetailsFont1.innerHTML = item.font
          state.textMockupDetailsApplication1.innerHTML = item.application
          state.textMockupDetailsLocation1.innerHTML = item.positio
          state.textMockupDetailsSize1.innerHTML = item.sizeInch
          if (item.colorNumber == "1") {
            state.textMockupDetailsColor1.appendChild(textColorDiv1)
            state.textMockupDetailsColor1.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColor1.appendChild(textColorDiv1)
            state.textMockupDetailsColor1.appendChild(textColorSpan1)
            state.textMockupDetailsColor1.appendChild(textColorDiv2)
            state.textMockupDetailsColor1.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColor1.appendChild(textColorDiv1)
            state.textMockupDetailsColor1.appendChild(textColorSpan1)
            state.textMockupDetailsColor1.appendChild(textColorDiv2)
            state.textMockupDetailsColor1.appendChild(textColorSpan2)
            state.textMockupDetailsColor1.appendChild(textColorDiv3)
            state.textMockupDetailsColor1.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetails1)
        } else if (state.currentTableNumber == 1) {
          // 设置标题
          state.textMockupDetailsTitle2.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValue2.appendChild(textValueSpan1)
          state.textMockupDetailsValue2.appendChild(textValueSpan2)
          state.textMockupDetailsValue2.appendChild(textValueSpan3)
          state.textMockupDetailsFont2.innerHTML = item.font
          state.textMockupDetailsApplication2.innerHTML = item.application
          state.textMockupDetailsLocation2.innerHTML = item.positio
          state.textMockupDetailsSize2.innerHTML = item.sizeInch
          if (item.colorNumber == "1") {
            state.textMockupDetailsColor2.appendChild(textColorDiv1)
            state.textMockupDetailsColor2.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColor2.appendChild(textColorDiv1)
            state.textMockupDetailsColor2.appendChild(textColorSpan1)
            state.textMockupDetailsColor2.appendChild(textColorDiv2)
            state.textMockupDetailsColor2.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColor2.appendChild(textColorDiv1)
            state.textMockupDetailsColor2.appendChild(textColorSpan1)
            state.textMockupDetailsColor2.appendChild(textColorDiv2)
            state.textMockupDetailsColor2.appendChild(textColorSpan2)
            state.textMockupDetailsColor2.appendChild(textColorDiv3)
            state.textMockupDetailsColor2.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetails2)
        } else if (state.currentTableNumber == 2) {
          // 设置标题
          state.textMockupDetailsTitle3.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValue3.appendChild(textValueSpan1)
          state.textMockupDetailsValue3.appendChild(textValueSpan2)
          state.textMockupDetailsValue3.appendChild(textValueSpan3)
          state.textMockupDetailsFont3.innerHTML = item.font
          state.textMockupDetailsApplication3.innerHTML = item.application
          state.textMockupDetailsLocation3.innerHTML = item.positio
          state.textMockupDetailsSize3.innerHTML = item.sizeInch
          if (item.colorNumber == "1") {
            state.textMockupDetailsColor3.appendChild(textColorDiv1)
            state.textMockupDetailsColor3.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColor3.appendChild(textColorDiv1)
            state.textMockupDetailsColor3.appendChild(textColorSpan1)
            state.textMockupDetailsColor3.appendChild(textColorDiv2)
            state.textMockupDetailsColor3.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColor3.appendChild(textColorDiv1)
            state.textMockupDetailsColor3.appendChild(textColorSpan1)
            state.textMockupDetailsColor3.appendChild(textColorDiv2)
            state.textMockupDetailsColor3.appendChild(textColorSpan2)
            state.textMockupDetailsColor3.appendChild(textColorDiv3)
            state.textMockupDetailsColor3.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetails3)
        } else {
          // 设置标题
          state.textMockupDetailsTitle4.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValue4.appendChild(textValueSpan1)
          state.textMockupDetailsValue4.appendChild(textValueSpan2)
          state.textMockupDetailsValue4.appendChild(textValueSpan3)
          state.textMockupDetailsFont4.innerHTML = item.font
          state.textMockupDetailsApplication4.innerHTML = item.application
          state.textMockupDetailsLocation4.innerHTML = item.positio
          state.textMockupDetailsSize4.innerHTML = item.sizeInch
          if (item.colorNumber == "1") {
            state.textMockupDetailsColor4.appendChild(textColorDiv1)
            state.textMockupDetailsColor4.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColor4.appendChild(textColorDiv1)
            state.textMockupDetailsColor4.appendChild(textColorSpan1)
            state.textMockupDetailsColor4.appendChild(textColorDiv2)
            state.textMockupDetailsColor4.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColor4.appendChild(textColorDiv1)
            state.textMockupDetailsColor4.appendChild(textColorSpan1)
            state.textMockupDetailsColor4.appendChild(textColorDiv2)
            state.textMockupDetailsColor4.appendChild(textColorSpan2)
            state.textMockupDetailsColor4.appendChild(textColorDiv3)
            state.textMockupDetailsColor4.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetails4)
        }
        state.currentTotalTableNumber++
        if (state.currentTableNumber == 3) {
          // 填完一页
          state.currentTableNumber = 0
          // 上传详情页
          uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          // 当前页数
          state.currentPageNumber++
        } else {
          state.currentTableNumber++
          if (state.currentTotalTableNumber == state.operationDataList.length + state.pageTowPageNumber) {
            // 上传详情页
            uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          }
        }
      }
    }

    const htmlAddTextNoSize = (textList) => {
      for (let item of textList) {
        let textValue = item.value === 'number' ? '1234567890' : item.value
        let textValueMockupDetailsSpanStyle1 = getTextValueMockupDetailsStyle(1, item.colorNumber, item.textColor1, item.font)
        let textValueMockupDetailsSpanStyle2 = getTextValueMockupDetailsStyle(2, item.colorNumber, item.textColor2, item.font)
        let textValueMockupDetailsSpanStyle3 = getTextValueMockupDetailsStyle(3, item.colorNumber, item.textColor3, item.font)
        // 创建文字span
        const textValueSpan1 = document.createElement('span');
        const textValueSpan2 = document.createElement('span');
        const textValueSpan3 = document.createElement('span');
        // 文字span添加样式
        for (let i in textValueMockupDetailsSpanStyle1){
          textValueSpan1.style[i] = textValueMockupDetailsSpanStyle1[i]
        }
        for (let i in textValueMockupDetailsSpanStyle2){
          textValueSpan2.style[i] = textValueMockupDetailsSpanStyle2[i]
        }
        for (let i in textValueMockupDetailsSpanStyle3){
          textValueSpan3.style[i] = textValueMockupDetailsSpanStyle3[i]
        }
        textValueSpan1.innerHTML = textValue
        textValueSpan2.innerHTML = textValue
        textValueSpan3.innerHTML = textValue

        // 文字div样式
        let textMockupDetailsColorDivStyle1 = getTextMockupDetailsColorDivStyle(item.textColor1)
        let textMockupDetailsColorDivStyle2 = getTextMockupDetailsColorDivStyle(item.textColor2)
        let textMockupDetailsColorDivStyle3 = getTextMockupDetailsColorDivStyle(item.textColor3)
        // 文字span样式
        let textMockupDetailsColorSpanStyle = {
          float: 'left',
          margin: '0px 20px 0px 5px',
        }
        // ------颜色1-----
        // 创建文字颜色DIV1
        const textColorDiv1 = document.createElement('div');
        // 文字颜色DIV1添加样式
        for (let i in textMockupDetailsColorDivStyle1){
          textColorDiv1.style[i] = textMockupDetailsColorDivStyle1[i]
        }
        // 创建文字颜色span1
        const textColorSpan1 = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan1.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan1.innerHTML = item.textColorName1
        // ------颜色2-----
        // 创建文字颜色DIV2
        const textColorDiv2 = document.createElement('div');
        // 文字颜色DIV2添加样式
        for (let i in textMockupDetailsColorDivStyle2){
          textColorDiv2.style[i] = textMockupDetailsColorDivStyle2[i]
        }
        // 创建文字颜色span2
        const textColorSpan2 = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan2.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan2.innerHTML = item.textColorName2
        // ------颜色3-----
        // 创建文字颜色DIV3
        const textColorDiv3 = document.createElement('div');
        // 文字颜色DIV3添加样式
        for (let i in textMockupDetailsColorDivStyle3){
          textColorDiv3.style[i] = textMockupDetailsColorDivStyle3[i]
        }
        // 创建文字颜色span3
        const textColorSpan3 = document.createElement('span');
        // 文字颜色span添加样式
        for (let i in textMockupDetailsColorSpanStyle){
          textColorSpan3.style[i] = textMockupDetailsColorSpanStyle[i]
        }
        textColorSpan3.innerHTML = item.textColorName3

        if (state.currentTableNumber == 0) {
          // 设置标题
          state.textMockupDetailsTitleNoSize1.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValueNoSize1.appendChild(textValueSpan1)
          state.textMockupDetailsValueNoSize1.appendChild(textValueSpan2)
          state.textMockupDetailsValueNoSize1.appendChild(textValueSpan3)
          state.textMockupDetailsFontNoSize1.innerHTML = item.font
          state.textMockupDetailsApplicationNoSize1.innerHTML = item.application
          state.textMockupDetailsLocationNoSize1.innerHTML = item.positio
          if (item.colorNumber == "1") {
            state.textMockupDetailsColorNoSize1.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize1.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColorNoSize1.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize1.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize1.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize1.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColorNoSize1.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize1.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize1.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize1.appendChild(textColorSpan2)
            state.textMockupDetailsColorNoSize1.appendChild(textColorDiv3)
            state.textMockupDetailsColorNoSize1.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetailsNoSize1)
        } else if (state.currentTableNumber == 1) {
          // 设置标题
          state.textMockupDetailsTitleNoSize2.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValueNoSize2.appendChild(textValueSpan1)
          state.textMockupDetailsValueNoSize2.appendChild(textValueSpan2)
          state.textMockupDetailsValueNoSize2.appendChild(textValueSpan3)
          state.textMockupDetailsFontNoSize2.innerHTML = item.font
          state.textMockupDetailsApplicationNoSize2.innerHTML = item.application
          state.textMockupDetailsLocationNoSize2.innerHTML = item.positio
          if (item.colorNumber == "1") {
            state.textMockupDetailsColorNoSize2.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize2.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColorNoSize2.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize2.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize2.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize2.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColorNoSize2.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize2.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize2.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize2.appendChild(textColorSpan2)
            state.textMockupDetailsColorNoSize2.appendChild(textColorDiv3)
            state.textMockupDetailsColorNoSize2.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetailsNoSize2)
        } else if (state.currentTableNumber == 2) {
          // 设置标题
          state.textMockupDetailsTitleNoSize3.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValueNoSize3.appendChild(textValueSpan1)
          state.textMockupDetailsValueNoSize3.appendChild(textValueSpan2)
          state.textMockupDetailsValueNoSize3.appendChild(textValueSpan3)
          state.textMockupDetailsFontNoSize3.innerHTML = item.font
          state.textMockupDetailsApplicationNoSize3.innerHTML = item.application
          state.textMockupDetailsLocationNoSize3.innerHTML = item.positio
          if (item.colorNumber == "1") {
            state.textMockupDetailsColorNoSize3.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize3.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColorNoSize3.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize3.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize3.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize3.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColorNoSize3.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize3.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize3.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize3.appendChild(textColorSpan2)
            state.textMockupDetailsColorNoSize3.appendChild(textColorDiv3)
            state.textMockupDetailsColorNoSize3.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetailsNoSize3)
        } else {
          // 设置标题
          state.textMockupDetailsTitleNoSize4.innerHTML = getTypeName(item.type)
          // 设置字体，位置，字号
          state.textMockupDetailsValueNoSize4.appendChild(textValueSpan1)
          state.textMockupDetailsValueNoSize4.appendChild(textValueSpan2)
          state.textMockupDetailsValueNoSize4.appendChild(textValueSpan3)
          state.textMockupDetailsFontNoSize4.innerHTML = item.font
          state.textMockupDetailsApplicationNoSize4.innerHTML = item.application
          state.textMockupDetailsLocationNoSize4.innerHTML = item.positio
          if (item.colorNumber == "1") {
            state.textMockupDetailsColorNoSize4.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize4.appendChild(textColorSpan1)
          } else if (item.colorNumber == "2") {
            state.textMockupDetailsColorNoSize4.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize4.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize4.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize4.appendChild(textColorSpan2)
          } else {
            state.textMockupDetailsColorNoSize4.appendChild(textColorDiv1)
            state.textMockupDetailsColorNoSize4.appendChild(textColorSpan1)
            state.textMockupDetailsColorNoSize4.appendChild(textColorDiv2)
            state.textMockupDetailsColorNoSize4.appendChild(textColorSpan2)
            state.textMockupDetailsColorNoSize4.appendChild(textColorDiv3)
            state.textMockupDetailsColorNoSize4.appendChild(textColorSpan3)
          }
          state.mockupDetails.appendChild(state.textMockupDetailsNoSize4)
        }
        state.currentTotalTableNumber++

        if (state.currentTableNumber == 3) {
          // 填完一页
          state.currentTableNumber = 0
          // 上传详情页
          uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          // 当前页数
          state.currentPageNumber++
        } else {
          state.currentTableNumber++
          if (state.currentTotalTableNumber == state.operationDataList.length + state.pageTowPageNumber) {
            // 上传详情页
            uploadPageTwo(state.currentPageNumber, state.totalPageNumber)
          }
        }
      }
    }

    // 上传svg
    const svgup = async (file) => {
      var formdata = new FormData()
      formdata.append('file', file)
      let data = '' as any
      await svgupload(formdata).then((res) => {
        data = res
      })
      return data
    }

    // 上传pdf详情页html文件
    const uploadPageTwo = async (currentPageNumber, totalPageNumber) => {
      // 详情页的html文件
      let twoPageFileBase = htmlToFileById('page2')
      // 删除容器的子元素
      while (state.textMockupDetailsValue1.hasChildNodes()) {
        state.textMockupDetailsValue1.removeChild(state.textMockupDetailsValue1.firstChild)
      }
      while (state.textMockupDetailsValue2.hasChildNodes()) {
        state.textMockupDetailsValue2.removeChild(state.textMockupDetailsValue2.firstChild)
      }
      while (state.textMockupDetailsValue3.hasChildNodes()) {
        state.textMockupDetailsValue3.removeChild(state.textMockupDetailsValue3.firstChild)
      }
      while (state.textMockupDetailsValue4.hasChildNodes()) {
        state.textMockupDetailsValue4.removeChild(state.textMockupDetailsValue4.firstChild)
      }
      while (state.imgMockupDetailsUrl1.hasChildNodes()) {
        state.imgMockupDetailsUrl1.removeChild(state.imgMockupDetailsUrl1.firstChild)
      }
      while (state.imgMockupDetailsUrl2.hasChildNodes()) {
        state.imgMockupDetailsUrl2.removeChild(state.imgMockupDetailsUrl2.firstChild)
      }
      while (state.imgMockupDetailsUrl3.hasChildNodes()) {
        state.imgMockupDetailsUrl3.removeChild(state.imgMockupDetailsUrl3.firstChild)
      }
      while (state.imgMockupDetailsUrl4.hasChildNodes()) {
        state.imgMockupDetailsUrl4.removeChild(state.imgMockupDetailsUrl4.firstChild)
      }
      while (state.mockupDetails.hasChildNodes()) {
        state.mockupDetails.removeChild(state.mockupDetails.firstChild)
      }

      // 上传代码
      // let twoPageUrlData: any = await htmlup(twoPageFileBase)
      await htmlup(twoPageFileBase).then(async (res: any) => {
        state.orderPdfUrl[currentPageNumber] = res.url
        if (state.orderPdfUrl.length !== totalPageNumber + 1) return
        let isAll = true
        for (let i = 0; i < state.orderPdfUrl.length; i++) {
          if (state.orderPdfUrl[i] === undefined) {
            isAll = false
          }
        }
        // console.log("==提交判断==isAll:", isAll)

        // 如果是最后一页，提交订单
        if (isAll) {
          // console.log("==提交订单==currentPageNumber:", currentPageNumber, "---", totalPageNumber)
          insertCustomerOrder()
        }
      })
    }

    // 提交订单
    const insertCustomerOrder = async () => {
      // 删除body容器的子元素
      while (state.colorMockup.hasChildNodes()) {
        state.colorMockup.removeChild(state.colorMockup.firstChild)
      }
      state.dialogVisible = false
      state.loading = true

      let pdfUrl = ''
      for (let i in state.orderPdfUrl) {
        if (parseInt(i) === 0) {
          pdfUrl = state.orderPdfUrl[i]
        } else {
          pdfUrl = pdfUrl + ',' + state.orderPdfUrl[i]
        }
      }
      state.orderinfo.orderFile = pdfUrl
      state.orderPdfUrl = []

      // 超时处理
      setTimeout(async () => {
        window.location.reload()
        // window.location.replace("http://localhost:8080/")
        window.location.replace("https://kustomizer.kobesportswear.com")
      }, 60 * 1000)
      //提交订单
      await insertOrder(state.orderinfo).then((res: any) => {
        if (res.code == 200) {
          ElMessage({
            message: res.msg,
            type: 'success',
          })
          console.log("==完成提交订单==")
          window.location.reload()
          // window.location.replace("http://localhost:8080/")
          window.location.replace("https://kustomizer.kobesportswear.com")
        } else {
          state.loading = false
          ElMessage.error('error submit!')
        }
      })
    }

    // 根据十六进制颜色值返回黑色或白色的选中标记颜色值
    const getColorHexContrary = (colorHex) => {
      if (colorHex.slice(1,3) == "FF" ||
          colorHex.slice(1,3) == "ff" ||
          colorHex.slice(1,3) == "CC" ||
          colorHex.slice(1,3) == "F8" ||
          colorHex.slice(1,3) == "6E" ||
          colorHex.slice(1,3) == "9D") {
        return "#000000"
      }
      return "#FFFFFF"
    }

    // 返回黑色或白色的名字
    const getColorHexContraryName = (colorHex) => {
      if (colorHex === "#000000") {
        return "BLACK"
      }
      return "WHITE"
    }

    // url转换为css动态样式的url
    const getImgMockupDetailsUrl = (url) => {
      let urlStr = ''
      let urlStrList  = 'url(' + url + ')'.split('"')
      for (let item of urlStrList) {
        urlStr += item
      }
      return urlStr
    }

    // 获取第二页MockupDetails文字Style
    const getTextValueMockupDetailsStyle = (number, colorNumber, color, font) => {
      let index = 1
      let stroke = ''
      let display = 'block' // block none
      if (number == 1) {
        index = 3
        stroke = '0px ' + color
      } else if (number == 2) {
        index = 2
        stroke = '4px ' + color
        if (colorNumber == '1')
          display = 'none'
      } else {
        index = 1
        stroke = '8px ' + color
        if (colorNumber == '1' || colorNumber == '2')
          display = 'none'
      }
      let style = {
        position: 'absolute',
        left: 0,
        right: 0,
        top: '40px',
        'font-size': '70px',
        'letter-spacing': '8px',
        color: color,
        'font-family': font,
        'z-index': index,
        '-webkit-text-stroke': stroke,
        display: display,
      }
      return style
    }

    // 获取第二页MockupDetails文字颜色Style
    const getTextMockupDetailsColorDivStyle = (backgroundColor) => {
      let style = {
        float: 'left',
        width: '18px',
        height: '18px',
        'border-radius': '20px',
        'border-width': '1px',
        'border-color': '#000',
        'border-style': 'solid',
        'background-color': backgroundColor,
      }
      return style
    }

    const htmlup = async (file) => {
      var formdata = { file: file }
      console.log(file, formdata)

      let data = '' as any
      await uploadhtml({ file: file }).then((res) => {
        data = res
      })
      return data
    }

    // 将svg转为base64
    const svgchangepng = (id) => {
      let down: any = document.getElementById(id)
      let s = new XMLSerializer().serializeToString(down)
      var encodedData = window.btoa(unescape(encodeURIComponent(s)))
      let src = 'data:image/svg+xml;base64,' + encodedData
      return base64toFile(src)
    }

    const htmlToFileById = (id) => {
      let down: any = document.getElementById(id)
      let s = new XMLSerializer().serializeToString(down)
      var encodedData = window.btoa(unescape(encodeURIComponent(s)))
      let src = 'data:image/svg+xml;base64,' + encodedData
      return base64toFile2(src)
    }

    const base64toFile2 = (dataurl, filename = 'file') => {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `text.html`, {
        type: mime,
      })
    }
    const base64toFile = (dataurl, filename = 'file') => {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.svg`, {
        type: mime,
      })
    }

    // 保存操作
    const saveOperation = (type) => {
      let operationInfo = {} as any
      let positio = ''
      let size = ''
      let sizeInch = ''
      switch (type) {
        case 'team_logo':
          if (!(state.teamLogoLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          if (!state.teamLogoImgUrl) {
            ElMessage({
              message: 'Please upload the picture.',
              type: 'warning',
            })
            return
          }

          positio = state.teamLogoLocationList[state.teamLogoLocationSelectedIndex].areaName
          sizeInch = state.teamLogoSizeList[state.teamLogoSizeSelectedIndex].label

          operationInfo = {
            application: state.teamLogoApplication,
            positio: positio,
            locationIndex: state.teamLogoLocationSelectedIndex,
            sizeIndex: state.teamLogoSizeSelectedIndex,
            sizeInch: sizeInch,
            content: state.teamLogoImgUrl,
            fileName: state.teamLogoFileName,
            type: 'team_logo',
          }
          break
        case 'number':
          if (!state.numberValue) {
            ElMessage({
              message: 'Please enter the number.',
              type: 'warning',
            })
            return
          }
          if (!(state.numberLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          if (!(state.numberColorNumber in [0,1,2,3])) {
            ElMessage({
              message: 'Please select the number of colours.',
              type: 'warning',
            })
            return
          }
          if (!(state.numberSizeSelectedIndex in [0,1,2,3])) {
            ElMessage({
              message: 'Please select the a size.',
              type: 'warning',
            })
            return
          }

          positio = state.numberLocationList[state.numberLocationSelectedIndex].areaName
          sizeInch = state.numberSizeList[state.numberSizeSelectedIndex].label

          operationInfo = {
            application: state.numberApplication,
            positio: positio,
            className: state.numberLocationList[state.numberLocationSelectedIndex].classNameList,
            locationIndex: state.numberLocationSelectedIndex,
            sizeIndex: state.numberSizeSelectedIndex,
            sizeInch: sizeInch,
            font: state.numberFamily,
            colorNumber: state.numberColorNumber,
            textColor1: state.numberColor[0],
            textColor2: state.numberColor[1],
            textColor3: state.numberColor[2],
            textColorName1: state.numberColorName[0],
            textColorName2: state.numberColorName[1],
            textColorName3: state.numberColorName[2],
            value: state.numberValue,
            type: type,
          }
          break
        case 'player_name':
          if (!state.playerNameValue) {
            ElMessage({
              message: 'Please enter the player name.',
              type: 'warning',
            })
            return
          }
          if (state.playerNameLocationList[state.playerNameLocationSelectedIndex].disabled) {
            ElMessage({
              message: 'This location is already in use.',
              type: 'warning',
            })
            return
          }
          if (!(state.playerNameColorNumber in [0,1,2,3])) {
            ElMessage({
              message: 'Please select the number of colours.',
              type: 'warning',
            })
            return
          }

          positio = state.playerNameLocationList[state.playerNameLocationSelectedIndex].areaName
          sizeInch = state.playerNameSizeList[state.playerNameSizeSelectedIndex].label

          operationInfo = {
            application: state.playerNameApplication,
            positio: positio,
            className: state.playerNameLocationList[state.playerNameLocationSelectedIndex].classNameList,
            locationIndex: state.playerNameLocationSelectedIndex,
            sizeIndex: state.playerNameSizeSelectedIndex,
            sizeInch: sizeInch,
            font: state.playerNameFamily,
            colorNumber: state.playerNameColorNumber,
            textColor1: state.playerNameColor[0],
            textColor2: state.playerNameColor[1],
            textColor3: state.playerNameColor[2],
            textColorName1: state.playerNameColorName[0],
            textColorName2: state.playerNameColorName[1],
            textColorName3: state.playerNameColorName[2],
            value: state.playerNameValue,
            type: type,
          }
          break
        case 'team_name':
          if (!state.teamNameValue) {
            ElMessage({
              message: 'Please enter the team name.',
              type: 'warning',
            })
            return
          }
          if (!state.teamNameSelecteArea) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }

          for (let item of state.teamNameAreaList) {
            if (item.className[0] == state.teamNameSelecteArea[0]) {
              positio = item.areaName
              size = item.sizeList[0].dictValue
              sizeInch = item.sizeList[0].dictLabel
            }
          }

          operationInfo = {
            className: state.teamNameSelecteArea,
            size: size,
            sizeInch: sizeInch,
            colorNumber: state.teamNameColorNumber,
            positio: positio,
            font: state.teamNameFamily,
            application: state.teamNameApplication,
            textColor1: state.teamNameNameColor[0],
            textColor2: state.teamNameNameColor[1],
            textColor3: state.teamNameNameColor[2],
            textColorName1: state.teamNameNameColorName[0],
            textColorName2: state.teamNameNameColorName[1],
            textColorName3: state.teamNameNameColorName[2],
            value: state.teamNameValue,
            type: 'team_name',
          }
          break
        case 'sponsor_name':
          if (!(state.sponsorNameLocationSelectedIndex in [0,1,2])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          if (!state.sponsorNameValue) {
            ElMessage({
              message: 'Please enter the sponsor name.',
              type: 'warning',
            })
            return
          }
          if (!(state.sponsorNameColorNumber in [0,1,2,3])) {
            ElMessage({
              message: 'Please select the number of colours.',
              type: 'warning',
            })
            return
          }
          positio = state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].areaName
          sizeInch = state.sponsorNameSizeList[state.sponsorNameSizeSelectedIndex].label

          operationInfo = {
            application: state.sponsorNameApplication,
            positio: positio,
            className: state.sponsorNameLocationList[state.sponsorNameLocationSelectedIndex].className,
            locationIndex: state.sponsorNameLocationSelectedIndex,
            sizeInch: sizeInch,
            font: state.sponsorNameFamily,
            colorNumber: state.sponsorNameColorNumber,
            textColor1: state.sponsorNameColor[0],
            textColor2: state.sponsorNameColor[1],
            textColor3: state.sponsorNameColor[2],
            textColorName1: state.sponsorNameColorName[0],
            textColorName2: state.sponsorNameColorName[1],
            textColorName3: state.sponsorNameColorName[2],
            value: state.sponsorNameValue,
            type: type,
          }
          break
        case 'sponsor_logo':
          if (!(state.sponsorLogoLocationSelectedIndex in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          if (!state.sponsorLogoImgUrl) {
            ElMessage({
              message: 'Please upload the picture.',
              type: 'warning',
            })
            return
          }

          positio = state.sponsorLogoLocationList[state.sponsorLogoLocationSelectedIndex].areaName
          sizeInch = state.sponsorLogoSizeList[state.sponsorLogoSizeSelectedIndex].label

          operationInfo = {
            application: state.sponsorLogoApplication,
            positio: positio,
            className: state.sponsorLogoLocationList[state.sponsorLogoLocationSelectedIndex].classNameList,
            locationIndex: state.sponsorLogoLocationSelectedIndex,
            sizeIndex: state.sponsorLogoSizeSelectedIndex,
            sizeInch: sizeInch,
            content: state.sponsorLogoImgUrl,
            fileName: state.sponsorLogoFileName,
            type: type,
          }
          break
        case 'flags_stop_signs':
          if ((state.canadaFlagShow && !(state.canadaFlagLocationSelectedIndex in [0,1,2,3,4]) ) ||
              (state.usaFlagShow && !(state.usaFlagLocationSelectedIndex in [0,1,2,3,4])) ||
              (!state.canadaFlagShow && !state.usaFlagShow && !state.stopSignShow)) {
            ElMessage({
              message: 'Please setting flags & stop sign.',
              type: 'warning',
            })
            return
          }
          saveOperation('stop_sign')
          break
        case 'stop_sign':
          if (state.stopSignShow) {
            positio = state.stopSignLocationList[state.stopSignLocationSelectedIndex].areaName
            sizeInch = state.stopSignLocationList[state.stopSignLocationSelectedIndex].sizeInch
            operationInfo = {
              application: state.stopSignApplication,
              positio: positio,
              className: state.stopSignLocationList[state.stopSignLocationSelectedIndex].className,
              locationIndex: state.stopSignLocationSelectedIndex,
              sizeInch: sizeInch,
              content: state.stopSignImgUrl,
              type: type,
            }
          }
          saveOperation('usa_flag')
          break
        case 'usa_flag':
          if (state.usaFlagShow && state.usaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            positio = state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].areaName
            sizeInch = state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].sizeInch

            operationInfo = {
              application: state.usaFlagApplication,
              positio: positio,
              className: state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].className,
              locationIndex: state.usaFlagLocationSelectedIndex,
              sizeInch: sizeInch,
              content: state.usaFlagImgUrl,
              type: type,
            }
          } else if (!state.usaFlagShow && state.usaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            banLocation(state.usaFlagLocationList[state.usaFlagLocationSelectedIndex].areaName, false)
            state.usaFlagLocationSelectedIndex = ''
          }
          saveOperation('canada_flag')
          break
        case 'canada_flag':
          if (state.canadaFlagShow && state.canadaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            positio = state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].areaName
            sizeInch = state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].sizeInch

            operationInfo = {
              application: state.canadaFlagApplication,
              positio: positio,
              className: state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].className,
              locationIndex: state.canadaFlagLocationSelectedIndex,
              sizeInch: sizeInch,
              content: state.canadaFlagImgUrl,
              type: type,
            }
          } else if (!state.canadaFlagShow && state.canadaFlagLocationSelectedIndex in [0,1,2,3,4]) {
            banLocation(state.canadaFlagLocationList[state.canadaFlagLocationSelectedIndex].areaName, false)
            state.canadaFlagLocationSelectedIndex = ''
          }
          break
        case 'c_a':
          if (!state.caValue) {
            ElMessage({
              message: 'Please select a letter.',
              type: 'warning',
            })
            return
          }
          if (!(state.caLocationSelectedIndex in [0,1,2])) {
            ElMessage({
              message: 'Please select a location.',
              type: 'warning',
            })
            return
          }
          if (!(state.caColorNumber in [0,1,2])) {
            ElMessage({
              message: 'Please select the number of colours.',
              type: 'warning',
            })
            return
          }

          positio = state.caLocationList[state.caLocationSelectedIndex].areaName
          sizeInch = state.caLocationList[state.caLocationSelectedIndex].sizeInch

          operationInfo = {
            application: state.caApplication,
            positio: positio,
            className: state.caLocationList[state.caLocationSelectedIndex].className,
            locationIndex: state.caLocationSelectedIndex,
            sizeInch: sizeInch,
            font: state.caFamily,
            colorNumber: state.caColorNumber,
            textColor1: state.caColor[0],
            textColor2: state.caColor[1],
            textColor3: state.caColor[2],
            textColorName1: state.caColorName[0],
            textColorName2: state.caColorName[1],
            textColorName3: state.caColorName[2],
            value: state.caValue,
            type: type,
          }
          break
      }
      if (Object.getOwnPropertyNames(operationInfo).length == 0) {
        initOperationData(type, true)
        return
      }
      state.operationDataList.push(operationInfo)
      console.log('===state.operationDataList===', state.operationDataList)

      // 初始化数据
      initOperationData(type, true)
      // 禁止相关位置
      banLocation(positio, true)
      state.show = 0
      state.ysshow = 0
    }

    // 删除操作项
    const deleteOperation = (type, className) => {
      // 取出需要删除的操作项
      let delIndex = -1 as any
      for (let i in state.operationDataList) {
        if (JSON.stringify(state.operationDataList[i].className) == JSON.stringify(className)) {
          // 取消禁用该位置
          banLocation(state.operationDataList[i].positio, false)
          delIndex = i
        }
      }
      // 设置原有的位置，用于删除SVG内容
      switch (type) {
        case 'team_logo':
          state.teamLogoLocationSelectedOldIndex = state.operationDataList[delIndex].locationIndex
          break
        case 'number':
          state.numberLocationSelectedOldIndex = state.operationDataList[delIndex].locationIndex
          break
        case 'team_name':
          state.teamNameSelecteAreaOld = state.operationDataList[delIndex].className
          break
        case 'sponsor_name':
          state.sponsorNameLocationSelectedOldIndex = state.operationDataList[delIndex].locationIndex
          break
        case 'sponsor_logo':
          state.sponsorLogoLocationSelectedOldIndex = state.operationDataList[delIndex].locationIndex
          break
        case 'canada_flag':
        case 'usa_flag':
        case 'stop_sign':
          initOperationData(type, false)
          break
        case 'c_a':
          state.caLocationSelectedOldIndex = state.operationDataList[delIndex].locationIndex
          break
      }
      // 移除编辑项
      if (delIndex != -1) {
        state.operationDataList.splice(delIndex, 1)
      }
      // 清除SVG图片
      clearSvgImg(type)
      // 清除SVG文字样式
      clearSvgText(type)

      // 设置原有的位置，用于删除SVG内容
      switch (type) {
        case 'team_logo':
          state.teamLogoLocationSelectedOldIndex = ''
          break
        case 'number':
          state.numberLocationSelectedOldIndex = ''
          break
        case 'team_name':
          state.teamNameSelecteAreaOld = ''
          break
        case 'sponsor_name':
          state.sponsorNameLocationSelectedOldIndex = ''
          break
        case 'sponsor_logo':
          state.sponsorLogoLocationSelectedOldIndex = ''
          break
        case 'c_a':
          state.caLocationSelectedOldIndex = ''
          break
      }
    }

    // 修改操作
    const modifyOperation = (type, className) => {
      // 隐藏back按钮
      state.showBack = false
      // 取出需要修改的操作项
      let delIndex = -1 as any
      for (let i in state.operationDataList) {
        if (JSON.stringify(state.operationDataList[i].className) == JSON.stringify(className)) {
          // 取消禁用该位置
          if (type !== 'canada_flag' && type !== 'usa_flag' && type !== 'stop_sign') {
            banLocation(state.operationDataList[i].positio, false)
          }
          state.operationOldData = state.operationDataList[i]
          delIndex = i
        }
      }
      // 移除编辑项
      if (delIndex != -1) {
        state.operationDataList.splice(delIndex, 1)
      }

      switch (type) {
        case 'team_logo':
          state.teamLogoApplication = state.operationOldData.application
          state.teamLogoLocationSelectedIndex = state.operationOldData.locationIndex
          state.teamLogoSizeSelectedIndex = state.operationOldData.sizeIndex
          state.teamLogoImgUrl = state.operationOldData.content
          state.teamLogoFileName = state.operationOldData.fileName
          // 显示页面
          state.show = type
          break
        case 'number':
          // 赋值
          state.numberApplication = state.operationOldData.application
          state.numberLocationSelectedOldIndex = state.operationOldData.locationIndex
          state.numberLocationSelectedIndex = state.operationOldData.locationIndex
          state.numberSizeSelectedIndex = state.operationOldData.sizeIndex
          state.numberFamily = state.operationOldData.font
          state.numberColorNumber = state.operationOldData.colorNumber
          state.numberColor[0] = state.operationOldData.textColor1
          state.numberColor[1] = state.operationOldData.textColor2
          state.numberColor[2] = state.operationOldData.textColor3
          state.numberColorName[0] = state.operationOldData.textColorName1
          state.numberColorName[1] = state.operationOldData.textColorName2
          state.numberColorName[2] = state.operationOldData.textColorName3
          state.numberValue = state.operationOldData.value
          // 显示页面
          state.show = type
          break
        case 'player_name':
          // 赋值
          state.playerNameApplication = state.operationOldData.application
          state.playerNameFamily = state.operationOldData.font
          state.playerNameColorNumber = state.operationOldData.colorNumber
          state.playerNameColor[0] = state.operationOldData.textColor1
          state.playerNameColor[1] = state.operationOldData.textColor2
          state.playerNameColor[2] = state.operationOldData.textColor3
          state.playerNameColorName[0] = state.operationOldData.textColorName1
          state.playerNameColorName[1] = state.operationOldData.textColorName2
          state.playerNameColorName[2] = state.operationOldData.textColorName3
          state.playerNameValue = state.operationOldData.value
          // 显示页面
          state.show = type
          break
        case 'team_name':
          // 赋值
          state.teamNameApplication = state.operationOldData.application
          state.teamNameSelecteAreaOld = state.operationOldData.className
          state.teamNameSelecteArea = state.operationOldData.className
          state.teamNameFamily = state.operationOldData.font
          state.teamNameColorNumber = state.operationOldData.colorNumber
          state.teamNameNameColor[0] = state.operationOldData.textColor1
          state.teamNameNameColor[1] = state.operationOldData.textColor2
          state.teamNameNameColor[2] = state.operationOldData.textColor3
          state.teamNameNameColorName[0] = state.operationOldData.textColorName1
          state.teamNameNameColorName[1] = state.operationOldData.textColorName2
          state.teamNameNameColorName[2] = state.operationOldData.textColorName3
          state.teamNameValue = state.operationOldData.value
          // 显示页面
          state.show = type
          break
        case 'sponsor_name':
          // 赋值
          state.sponsorNameApplication = state.operationOldData.application
          state.sponsorNameLocationSelectedOldIndex = state.operationOldData.locationIndex
          state.sponsorNameLocationSelectedIndex = state.operationOldData.locationIndex
          state.sponsorNameFamily = state.operationOldData.font
          state.sponsorNameColorNumber = state.operationOldData.colorNumber
          state.sponsorNameColor[0] = state.operationOldData.textColor1
          state.sponsorNameColor[1] = state.operationOldData.textColor2
          state.sponsorNameColor[2] = state.operationOldData.textColor3
          state.sponsorNameColorName[0] = state.operationOldData.textColorName1
          state.sponsorNameColorName[1] = state.operationOldData.textColorName2
          state.sponsorNameColorName[2] = state.operationOldData.textColorName3
          state.sponsorNameValue = state.operationOldData.value
          // 显示页面
          state.show = type
          break
        case 'sponsor_logo':
          state.sponsorLogoApplication = state.operationOldData.application
          state.sponsorLogoLocationSelectedOldIndex = state.operationOldData.locationIndex
          state.sponsorLogoLocationSelectedIndex = state.operationOldData.locationIndex
          state.sponsorLogoSizeSelectedIndex = state.operationOldData.sizeIndex
          state.sponsorLogoImgUrl = state.operationOldData.content
          state.sponsorLogoFileName = state.operationOldData.fileName
          // 显示页面
          state.show = type
          break
        case 'canada_flag':
        case 'usa_flag':
        case 'stop_sign':
          for (let item of state.operationDataList) {
            if (item.type == 'canada_flag') {
              state.canadaFlagShow = true
              state.canadaFlagLocationSelectedOldIndex = item.locationIndex
              state.canadaFlagLocationSelectedIndex = item.locationIndex
              state.canadaFlagApplication = item.application
              delFlagOperation(item.type)
            }
          }
          for (let item of state.operationDataList) {
            if (item.type == 'usa_flag') {
              state.usaFlagShow = true
              state.usaFlagLocationSelectedOldIndex = item.locationIndex
              state.usaFlagLocationSelectedIndex = item.locationIndex
              state.usaFlagApplication = item.application
              delFlagOperation(item.type)
            }
          }
          for (let item of state.operationDataList) {
            if (item.type == 'stop_sign') {
              state.stopSignShow = true
              state.stopSignLocationSelectedIndex = item.locationIndex
              state.stopSignApplication = item.application
              delFlagOperation(item.type)
            }
          }
          // 显示页面
          state.show = 'flags_stop_signs'
          break
        case 'c_a':
          // 赋值
          state.caApplication = state.operationOldData.application
          state.caLocationSelectedOldIndex = state.operationOldData.locationIndex
          state.caLocationSelectedIndex = state.operationOldData.locationIndex
          state.caFamily = state.operationOldData.font
          state.caColorNumber = state.operationOldData.colorNumber
          state.caColor[0] = state.operationOldData.textColor1
          state.caColor[1] = state.operationOldData.textColor2
          state.caColor[2] = state.operationOldData.textColor3
          state.caColorName[0] = state.operationOldData.textColorName1
          state.caColorName[1] = state.operationOldData.textColorName2
          state.caColorName[2] = state.operationOldData.textColorName3
          state.caValue = state.operationOldData.value
          // 显示页面
          state.show = type
          break
      }
    }

    const updateIcon = (type) => {
      if (type == 'jersey_tie_down') {
        let classNames: any = document.getElementsByClassName("jersey_tie_down_b_lower_back")
        for (let item of classNames) {
          item.style.display = state.jerseyTieDownShow ? 'block' : 'none'
        }
      }
    }

    // 获取类型名称
    const getTypeName = (type) => {
      switch (type) {
        case 'team_logo':
          return 'TEAM LOGO'
        case 'number':
          return 'NUMBER'
        case 'player_name':
          return 'PLAYER NAME'
        case 'team_name':
          return 'TEAM NAME'
        case 'sponsor_name':
          return 'SPONSOR NAME'
        case 'sponsor_logo':
          return 'SPONSOR LOGO'
        case 'canada_flag':
        case 'usa_flag':
        case 'stop_sign':
          return 'FLAGS & STOP SIGN'
        case 'c_a':
          return 'CAPTAIN’S & ALTERNATES'
      }
    }

    return {
      ...toRefs(state),
      updateIcon,
      deleteOperation,
      modifyOperation,
      saveOperation,
      submitForm,
      switchMenu,
      selecteColor,
      rollBack,
      switchColor,
      getTypeName,
      getColorHexContrary,
      selecteLocation,
      switchFlagShow,
      selecteSize,
      selecteColorNumber,
      updateSvgTextFont,
      updateSvgText,
      uploadFile,
      selecteFile,
      placeanOrder,
      exitDialog,
      exit,
      ruleFormRef,
      rules,
      inputText,
    }
  },
})
