
import { computed, defineComponent } from "vue";
import heade from "./views/Head.vue";
import foote from "./views/Footer.vue";
export default defineComponent({
  components: {
    heade,
    foote,
  },
  setup() {},
});
