// 导入axios实例
import httpRequest from '@/request/index'


//svg正面
export function apiGetUserInfoz(url) {
	return httpRequest({
		dataType: "svg",
		url: `${url}`,
		method: 'get',
	})
}
//svg反面
export function apiGetUserInfof(url) {
	return httpRequest({
		dataType: "svg",
		url: `${url}`,
		method: 'get',
	})
}
//获取总区域列表id
export function gettotalarealistid(params) {
	return httpRequest({
		url: 'at/area/web-list',
		method: 'get',
		params: params,
	})
}
//获取总区域列表
export function gettotalarealist(params) {
	return httpRequest({
		url: '/at/areaColorMode/web-list',
		method: 'get',
		params: params,
	})
}
//获取颜色列表
export function getcolorlist(params) {
	return httpRequest({
		url: '/at/areaColor/web-list',
		method: 'get',
		params: params,
	})
}
// 获取文字颜色列表

export function gettextcolor() {
	return httpRequest({
		url: '/system/dict/data/type/web/at_text_color',
		method: 'get',
	})
}
// 获取文字尺寸
export function gettextsize(data) {
	return httpRequest({
		url: `/system/dict/data/type/web/${data}`,
		method: 'get',
	})
}
// 获取字体列表
export function getTextFont() {
	return httpRequest({
		url: '/system/dict/data/type/web/at_text_font_family',
		method: 'get',
	})
}
// 提交订单
export function insertOrder(params) {
	return httpRequest({
		url: '/order/insertOrder',
		method: 'post',
		data: params,
	})
}
// svg上传
export function svgupload(params) {
	return httpRequest({
		url: '/common/uploadLogo',
		method: 'post',
		headers: { 'Content-Type': "multipart/form-data" },
		data: params,
	})
}
// 获取衣服尺寸
export function clothingsize() {
	return httpRequest({
		url: '/at/goodsDetails/web-clothing-size',
		method: 'get',
	})
}
// 获取颜色名称

export function getColorName(color) {
	return httpRequest({
		url: `/at/color/findColorNameByHexadecimal?colorHexadecimal=${color}`,
		method: 'get',
	})
}

export function getColorList(color) {
	return httpRequest({
		url: `/at/color/list`,
		method: 'get',
	})
}

// 上传html
export function uploadhtml(params) {
	return httpRequest({
		url: `/common/uploadHtmlSvg`,
		method: 'post',
		headers: { 'Content-Type': "multipart/form-data" },
		data: params,
	})
}

// 获取订单编号
export function getOrderNumber() {
	return httpRequest({
		url: '/order/getOrderNumber',
		method: 'get',
	})
}