
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

export default defineComponent({
  setup() {
    const state = reactive({
      imglist: [
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/homecta2-1.png',
          txt: 'KOBE PMS COLOURS',
          btn: 'Download',
          img: 'https://kobesportswear.com/wp-content/themes/kobesportswear/img/refresh/cta_stars.png',
        },
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/homecta3-1.png',
          txt: 'NHL/PRO TEAM COLOURS',
          btn: 'view',
          img: 'https://kobesportswear.com/wp-content/themes/kobesportswear/img/refresh/cta_stars.png',
        },
      ],
      listinfo: [
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/icon1.png',
          title: 'STEP',
          txt: 'Send in an image or sample of a product or design that includes total quantities, sizes and desired material or fabric so we can provide a quote.',
        },
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/icon2.png',
          title: 'STEP',
          txt: 'Select colours using PMS #’s.See below for PMS Options.',
        },
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/i3.png',
          title: 'STEP',
          txt: 'Select colours using PMS #’s.See below for PMS Options.',
        },
      ],
      listinfo2: [
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/i4.png',
          title: 'STEP',
          txt: 'Chose any existing in-stock Kobe jersey and change the colours to a design of your choice.',
        },
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/icon1.png',
          title: 'STEP',
          txt: 'Send us a design to replicate by creating a new or custom pattern.',
        },
        {
          url: 'https://kobesportswear.com/wp-content/uploads/2022/10/i5.png',
          title: 'STEP',
          txt: 'Change or add features to any jerseys.',
          btn: 'Coming Soon',
        },
      ],
    })

    return {
      ...toRefs(state),
    }
  },
})
