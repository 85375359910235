import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Custom from '../views/Custom.vue'
import Customizer from '../views/Customizer.vue'
// import Productlist from '../views/Productlist.vue'
import Details from '../views/Details.vue'
import aixin from '../views/aixin.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/customizer',
    name: 'customizer',
    component: Customizer
  },
  // {
  //   path: '/',
  //   name: 'page',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/HomePage.vue')
  // },

  {
    path: '/',
    name: 'page',
    component: () => import(/* webpackChunkName: "about" */ '../views/Productlist.vue')
  },

  // {
  //   path: '/productlist',
  //   name: 'productlist',
  //   component: Productlist
  // },
  {
    path: '/details',
    name: 'details',
    component: Details
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/custom',
    name: 'custom',
    component: Custom
  },
  {
    path: '/aixin',
    name: 'aixin',
    component: aixin
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
