import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '../src/assets/css/app.css'
import '@/assets/font/font.css'
import axios from 'axios'

//点击跳转后固定在页面头部
router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})


createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
