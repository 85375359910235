// 导入axios实例
import httpRequest from '@/request/index'
// 获取轮播图
export function getdetailslist(){
    return httpRequest({
		url: '/at/banner/web-list',
		method: 'get',
	})
}
//获取分类
export function getgoodscategory( ) {
    return httpRequest({
		url: '/at/goodsClassification/web-list',
		method: 'get',
	})
}
// 获取商品列表
export function getGoodsList(params) {
    console.log(params);
    return httpRequest({
		url: `/at/goodsDetails/web-list`,
		method: 'get',
        params: params,
	})
}
// 获取商品详情
export function getGoodsInfo(params) {
    console.log(params);
    return httpRequest({
		url: `at/goodsDetails/web/${params}`,
		method: 'get',
	})
}
