
import { defineComponent, reactive, toRefs } from "vue";
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  setup() {
    const state = reactive({
      color1: "#FF0000" as any,
      color2: "#FF0000" as any,
      color3: "#FF0000" as any,
      color4: "" as any,
      color5: "" as any,
      sjx: "" as any,
      yx: "" as any,
      yx2: "" as any,
      yx3: "" as any,
      yx4: "" as any,
      zfx: "" as any,
      input: "" as any,
      text: "" as any,
      s: 1 as any,
      red: '' as any,
      blue:'' as any
    });
    const show=(e:any)=>{
      state.s=e
      // console.log(state.s);
      
    }
    const sanjiaox = (e: any) => {
      console.log(e);
      state.sjx = document.getElementById("sjx");
      if (state.sjx) {
        state.sjx.style.borderBottomColor = e;
      }
      console.log(state.sjx.style);
    };
    const yunax = (e: any) => {
      // console.log(e);
      state.yx = document.getElementById("yx");
      state.red = document.getElementById("red");
      if (state.yx) {
        state.yx.style.background = e;
        state.red.style.background = e;
      }
      //  console.log(state.yx.style);
    };
    const sjxyx = (e: any) => {
      // console.log(e);
      state.yx = document.getElementById("yx");
      state.sjx = document.getElementById("sjx");
      state.color1 = e;
      state.color2 = e;
      if (state.sjx) {
        state.sjx.style.borderBottomColor = e;
      }
      if (state.yx) {
        state.yx.style.background = e;
      }
      //  console.log(state.yx.style);
    };
    const zfx = (e: any) => {
      console.log(e);
      state.zfx = document.getElementById("zfx");
      if (state.zfx) {
        state.zfx.style.background = e;
      }
      //  console.log(state.zfx.style);
    };
    const qb = (e: any) => {
      // console.log(e);
      state.yx = document.getElementById("yx");
      state.sjx = document.getElementById("sjx");
      state.zfx = document.getElementById("zfx");
      state.color1 = e;
      state.color2 = e;
      state.color3 = e;
      state.color4 = e;
      if (state.zfx) {
        state.zfx.style.background = e;
      }
      if (state.sjx) {
        state.sjx.style.borderBottomColor = e;
      }
      if (state.yx) {
        state.yx.style.background = e;
      }
      //  console.log(state.yx.style);
    };
    const sjxys = (e: any) => {
      state.sjx = document.getElementById("sjx");
      state.yx2 = document.getElementById("yx2");
      state.yx3 = document.getElementById("yx3");
      state.yx4 = document.getElementById("yx4");
      state.blue = document.getElementById("blue");
      state.yx2.style.background = e;
      state.yx3.style.background = e;
      state.yx4.style.background = e;
      state.blue.style.background = e;
      if (state.sjx) {
        state.sjx.style.borderBottomColor = e;
      }
    };
    const yxys = (e: any) => {
      state.yx = document.getElementById("yx");
      if (state.yx) {
        state.yx.style.background = e;
      }
    };
    const zfxys = (e: any) => {
      state.zfx = document.getElementById("zfx");
      if (state.zfx) {
        state.zfx.style.background = e;
      }
    };
    const sjxandyx = (e: any) => {
      // console.log(e);
      state.yx = document.getElementById("yx");
      state.sjx = document.getElementById("sjx");
      if (state.sjx) {
        state.sjx.style.borderBottomColor = e;
      }
      if (state.yx) {
        state.yx.style.background = e;
      }
      //  console.log(state.yx.style);
    };
    const suoyou = (e: any) => {
      // console.log(e);
      state.yx = document.getElementById("yx");
      state.yx2 = document.getElementById("yx2");
      state.yx3 = document.getElementById("yx3");
      state.yx4 = document.getElementById("yx4");
      state.sjx = document.getElementById("sjx");
      state.zfx = document.getElementById("zfx");
      if (state.zfx) {
        state.zfx.style.background = e;
      }
      if (state.sjx) {
        state.sjx.style.borderBottomColor = e;
      }
      if (state.yx) {
        state.yx.style.background = e;
        state.yx2.style.background = e;
        state.yx3.style.background = e;
        state.yx4.style.background = e;
      }
      //  console.log(state.yx.style);
    };
    const text = (e: any) => {
      state.text = document.getElementById("text");
      if (state.text) {
        state.text.style.color = e;
      }
      // console.log(state.text.style);
    };
    return {
      ...toRefs(state),
      sanjiaox,
      yunax,
      zfx,
      sjxyx,
      qb,
      sjxys,
      yxys,
      zfxys,
      sjxandyx,
      suoyou,
      text,
      show,
    };
  },
});
